header {
  position: relative;
  margin-bottom: 30px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 75px;
    background: $light-black;
    z-index: 1;
  }
}

.header-title {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  .grid {
    grid-column-gap: 0;
  }
  h1 {
    color: $white;
    font-weight: bold;
    font-size: rem-calc(50);
    line-height: 1.2em;
  }
}

.ie11 {
  .header-title {
    position: absolute;
    top: 40%;
    left: 45%;
    transform: translateX(-45%);
  }

  .column.search-wrapper {
    width: 65%;
  }
}

.logo-section {
  a {
    &:hover {
      border: none;
    }
  }
}

.header-top {
  position: relative;
  z-index: 2;
  padding-top: 10px;

  .search-wrapper {
    position: relative;
    grid-column: 3 / 3;
    text-align: right;
  }



  .blue-buttons-container {
    max-width: rem-calc(984);
    margin-top: -80px;
    .bg-buttons {
      padding: 30px;
      font-size: rem-calc(28);
      line-height: 1;
      span {
        padding-left: 18px;
        padding-right: 0;
        &:after {
          display: none;
        }
      }
    }
  }
}

.front-header-image {
  margin-top: -60px;
  @include bg-image(13%);
}

.mobile-menu-toggler {
  margin-left: 10px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 15px;
  height: 16px;
  box-shadow: inset 0 2px 0px 0 $dark-gray;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 15px;
    height: 2px;
    background: $dark-gray;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 15px;
    height: 2px;
    background: $dark-gray;
  }
}

@media only screen and (max-width: 1220px) {
  .front-header-image {
    padding: 19% 0;
  }
}

@include tablet-landscape {
  .header-title {
    top: 44%;
  }
  .header-title {
    .grid {
      grid-template-columns: repeat(auto-fill, minmax(51%, 1fr));
    }
  }
  .header-top {
    .blue-buttons-container {
      margin-top: -70px;
      max-width: rem-calc(900);
      .bg-buttons {
        padding: 23px;
        font-size: rem-calc(21);
      }
    }
  }
}

.header-mobile {
  display: none;
}

@include tablet-portrait-down {
  header {
    margin: 0;
    &:before {
      height: 120px;
    }
  }

  .header-top, .blue-buttons-container, nav,
  .header-top .front-header-image {
    display: none;
  }

  .header-mobile {
    display: block;
    .front-header-image {
      padding: 30% 0;
      background-position: 100% !important;
    }
  }

  .mobile-menu-with-logo {
    position: relative;
    display: block;
    z-index: 23;
    padding: 0 15px;
    .logo-wrapper {
      width: 35%;
      z-index: 2;
      position: relative;
    }
    .search-wrapper {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      padding: 4px 25px;
      background: #fff;
      text-align: right;
      z-index: 1;
    }
  }

  .header-image {
    position: relative;

    h1 {
      padding: 0 25px;
      position: absolute;
      top: 30%;
      left: 0;
      transform: translateY(-30%);
      color: $white;
      font-weight: bold;
      font-size: rem-calc(34);
      line-height: 1.2em;
    }
  }

  .mobile-menu {
    z-index: 3;
    display: none;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    background: $white;

    &.visible {
      opacity: 1;
      display: block;
    }
    .menu-1, .menu-2, .menu-3 {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid $yellow;
      h2 {
        padding: 0 25px;
        margin-bottom: 10px;
        font-size: rem-calc(20);
        font-weight: 300;
      }
      ul {
        padding: 0 25px;
      }
      li {
        margin-bottom: 5px;
        &.active {
          a {
            font-weight: bold;
          }
        }
      }
      a {
        font-size: rem-calc(16);
      }
    }
  }
}


@media only screen and (max-width: 600px) {
  header {
    &:before {
      height: 100px;
    }
  }
  .mobile-menu-with-logo {
    .logo-wrapper {
      width: 60%;
    }
  }

  .header-mobile {

    .front-header-image {
      padding: 40% 0;
    }

    .header-image {
      margin-top: 35px;
    }
  }
}
