.search {
  margin-bottom: 15px;
  .block-title-2 {
    margin-top: 40px;
    margin-bottom: 15px;
  }

  article {
    margin-bottom: 30px;
    .date, .category {
      color: $darker-gray;
      font-size: 15px;
      font-weight: bold;
    }
    .article-title {
      margin-bottom: 4px;
      font-size: 15px;
    }
    .article-summary {
      margin-bottom: 6px;
    }
    .search-link {
      font-weight: bold;
    }
  }
}
