.pagination {
  margin-top: 40px;

  a {
    color: $dark-gray;
    font-size: rem-calc(12);
    &:hover {
      border: none;
    }
  }
  .active {
    a {
      padding: 9px;
      background: $black;
      color: $white;
    }
  }
}
