.search-program-page {
  padding: 30px 0 40px 0;
  background: $main-bg;

  h1 {
    margin-top: 40px;
    margin-bottom: 15px;
  }

  .search-top {
    margin-top: 20px;
  }

  .perifereia-ependisis, .dikaiouxoi, .drastiriotita {
    margin-bottom: 20px;
    box-shadow: -1px 7px 21px -1px rgba($dark-gray, 0.67);

  }

  .search-main {
    margin-top: 20px;
    .checkbox-container {
      margin-bottom: 10px;
      font-weight: normal;
    }
  }

  .search-footer {
    margin-top: 20px;
    grid-template-columns: repeat(auto-fill, minmax(48%, auto));

    p {
      text-align: center;
    }
  }

  .results {
    grid-column: 2 / 4;
    @include tablet-portrait-down {
      margin-top: 30px;
      grid-column: auto;
    }
  }

  .company-size {
    .six-columns {
      grid-template-columns: repeat(auto-fill, minmax(47%, auto));
    }
    .radio-container {
      border: 1px solid #f2f2f2;

      .clearfix {
        &:last-child {
          margin-top: 10px;
        }
      }

      p {
        margin-top: 0;
        width: 80%;
      }

      input {
        margin: 0;
        float: right;
      }
    }
  }
}

.ie11 {
  .search-program-page {
    .results {
      width: 68%;
    }
  }
}

.input-container {
  margin-bottom: 20px;
  padding: 14px 18px;
  background: $white;
  color: $black;
  box-shadow: -1px 7px 21px -1px rgba($black,0.67);
  input {
    box-shadow: none;
    width: 100%;
    border: 1px solid $border-color;
  }
}

.select-container {
  padding: 14px 18px;
  background: $white;
  color: $black;

  h3 {
    margin-bottom: 5px;
    font-size: rem-calc(19);
  }
  select {
    margin-bottom: 5px;
    height: 46px;
    max-width: 400px;
    width: 100%;
    padding: 12px 24px;
    border: 1px solid $border-color;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    font-size: rem-calc(14);
  }
}

.compare {
  position: relative;
  display: inline-block;
  float: right;
  color: $darker-gray;
  text-decoration: underline;
  &:before {
    content: '';
    margin-right: 5px;
    width: 17px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    background: url('../img/form-icons.png') no-repeat 0 0;
  }
}

.clear-filters {
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  color: $darker-gray;
  text-decoration: underline;
  &:before {
    content: '';
    margin-right: 5px;
    width: 17px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    background: url('../img/form-icons.png') no-repeat 0 -20px;
  }
}

.save-search {
  position: relative;
  display: inline-block;
  color: $darker-gray;
  text-decoration: underline;
  &:before {
    content: '';
    margin-right: 5px;
    width: 17px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    background: url('../img/form-icons.png') no-repeat 0 -43px;
  }
}

.search-submit-wrapper {
  margin-top: 22px;
  text-align: center;
  .button {
    padding: 16px 29px;
  }
}

.mobile-filter-toggler {
  display: none;
  background: $black;
  color: white;
  padding: 13px;
  text-align: center;
  border-radius: 2px;
  @include tablet-portrait-down {
    display: block;
  }
}

@include tablet-portrait-down {

  .filters-container {
    margin-top: 20px;
    display: none;
    &.active {
      display: block;
    }
  }


  .program-attributes {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
    .column {
      margin-bottom: 10px;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .invest-container, .epidotisi-container {
    border: none;
  }

  .program-card {
    .inline-list {
      display: inline-block;
      li {
        margin-left: 0;
        margin-bottom: 10px;
        display: inline-block;
      }
    }
  }
}
