.contact-us-block {
  padding: 30px 0;
  text-align: center;
  @include tablet-portrait-down {
    padding: 15px 0;
  }

  .block-title-2 {
    color: $black;
  }

  .inline-list {
    margin: 25px 0;
    li {
      padding-left: 10px;
      border-left: 1px solid $yellow;
      color: $darker-gray;
      line-height: 1;
      font-weight: bold;
    }
    @include tablet-portrait-down {
      display: block;
      li {
        margin: 0 0 15px 0;
        display: inline-block;
        text-align: left;
      }
    }
  }

  p {
    color: $darker-gray;
    @include tablet-portrait-down {
      text-align: left;
    }
  }

  .ways-to-communicate {
    grid-column-gap: 20px;
    margin-top: 55px;
    text-align: left;

    .block-title-2 {
      margin: 15px 0;
    }

    p {
      margin-bottom: 15px;
      a {
        color: $blue;
        &:hover {
          border: none;
          color: $yellow;
          text-decoration: underline;
        }
      }
    }
  }
}
