.welcome-section {
  position: relative;
  z-index: 2;
  margin-top: 50px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  box-shadow: 0 15px 25px -8px rgba($dark-gray,1);
}

.welcome-message {
  padding-top: 30px;
  padding-bottom: 40px;
  border-top: 1px solid $light-gray;
  border-bottom: 1px solid $light-gray;
  article {
    max-width: rem-calc(920);
    text-align: center;
  }

  .title {
    margin-bottom: 28px;
    color: $blue;
    font-size: rem-calc(28);
    font-weight: 300;
  }
}

.welcome-points {
  margin-top: 20px;
  article {
    text-align: center;
  }

  .title {
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: rem-calc(15);
    font-weight: bold;
  }
}

@include mobile-only {
  .welcome-message {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .welcome-points {
    max-width: rem-calc(220);
    .column {
      margin-bottom: 20px;
    }
  }
}
