.social-media-share {
  margin: 40px 0 30px 0;
}

.download-link {
  padding: 14px 17px;
  position: relative;
  background: $black;
  font-weight: bold;
  &:after {
    content: '';
    margin-left: 10px;
    width: 22px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    background: url('../img/blue-dl.png') no-repeat;
  }
  &:hover {
    border: none;
    color: $yellow;
    text-decoration: underline;
  }
}

.share-button {
  outline: 0;
  width: 50px;
  height: 50px;
  background: transparent;
  text-indent: -9999px;
  background: url('../img/share-icons.png') no-repeat;
  background-size: cover;
  box-shadow: -1px 7px 21px -1px rgba($dark-gray,0.67);
  &.fb-share {
    background-position: 0 -72px;
  }
  &.twitter-share {
    background-position: 0 -146px;
  }
  &.linkedin-share {
    background-position: 0 -222px;
  }
  &.print-share {
    background-position: 0 0;
  }
}


@include mobile-only {
  .social-media-share {
    .inline-list {
      display: block;
      text-align: right;
      li {
        display: inline-block;
        vertical-align: middle;
        // &:first-child {
        //   margin-bottom: 20px;
        //   display: block;
        // }
        &:last-child {
          display: none;
        }
      }
    }
  }
}
