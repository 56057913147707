@mixin bg-button($bg-color, $border-color) {
  display: block;
  padding: 21px 25px;
  background: $bg-color;
  border: none;
  font-size: rem-calc(20);
  line-height: 1.3;
  font-weight: 300;
  &:hover {
    border: none;
    background: lighten(desaturate(adjust-hue($bg-color, -2), 23.64), 13.73);
    text-decoration: none;
  }
  span {
    padding-left: 10px;
    padding-right: 15px;
    position: relative;
    display: block;
    border: none;
    border-left: 2px solid $border-color;
    color: $white;

    &:after {
      content: '';
      margin-top: 5px;
      position: absolute;
      right: 0;
      top: 0;
      width: 10px;
      height: 16px;
      background: url('../img/contact-icons.png') 0 -128px;
    }
  }
}
