/* Customize the label (the checkbox-container) */
.checkbox-container {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-weight: bold;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

/* Customize the label (the radio-container) */
.radio-container {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-weight: bold;
}

/* Hide the browser's default checkbox */
.radio-container input {
  position: absolute;
  top: 4px;
  left: 0;
  cursor: pointer;
}

.search-checkbox-container {
  display: none;
  position: absolute;
  top: -10px;
  background: $white;
  opacity: 0;
}

.search-form-section {
  padding: 20px;
  background: $white;
}
.search-form {
  display: flex;
  align-items: center;
  border-bottom: 2px solid $light-gray;
  input {
    width: 330px;
    padding: 8px 5px;
    border: 0;
    border-top: 1px solid $light-gray;
    background: $white;
  }

  button {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    border: 0;
    box-shadow: none;
    background: url('../img/search-icon.png') no-repeat 0 -1px;
    outline: 0;
    text-indent: -9999px;
    @mixin tablet-portrait-down {
      margin-left: 0;
    }
  }
}

.datepicker-wrapper {
  position: relative;
  &:after {
    content: '';
    width: 23px;
    height: 22px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    background: url('../img/calendar.png') no-repeat;
  }
}

.hasDatepicker {
  cursor: pointer;
  padding: 18px 25px;
  border: none;
  box-shadow: -1px 7px 21px -1px rgba($dark-gray,0.67);
  &:focus {
    outline: 0;
  }
}

select {
  max-width: 100%;
  padding: 14px 25px;
  border: none;
  box-shadow: -1px 7px 21px -1px rgba($dark-gray,0.67);
  &:focus {
    outline: 0;
  }
}

[type="text"], [type="email"], [type="number"] {
  padding: 15px;
  border: none;
  background: $white;
  color: $black;
  box-shadow: -1px 7px 21px -1px rgba($dark-gray,0.67);
}

textarea {
  outline: none;
  resize: none;
  max-width: 100%;
  border: none;
  box-shadow: -1px 7px 21px -1px rgba($dark-gray,0.67);
  color: $black;
}

.input-group {
  margin-bottom: 70px;
  display: table;
  .input {
    display: table-cell;
  }
  input[type="search"] {
    padding: 12px 24px;
    color: #555;
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 0;
    box-shadow: inset 0 1px 1px rgba($black,0.075);
    font-size: 14px;
    line-height: 1;
    outline: 0;
    &:focus {
      box-shadow: inset 0 1px 1px rgba($black,.075), 0 0 8px rgba($dark-gray,0.6)
    }
  }
  input[type="submit"] {
    background: $blue;
    color: $white;
    padding: 15px;
    border: 0;
    line-height: 1;
    cursor: pointer;
  }
}
