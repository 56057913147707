.article {

  .grid-container {
    max-width: rem-calc($grid-container);
  }

  .article-container {
    margin-top: 50px;

    .grid {
      grid-column-gap: 25px;
    }
  }

  .article-wrapper {
    grid-column: 1 / 3;
    @include tablet-portrait-down {
      grid-column: auto;
    }

    .block-title-2 {
      margin-bottom: 40px;
    }

    .date {
      font-size: rem-calc(11);
      font-weight: bold;
    }

    h1 {
      margin-bottom: 25px;
      margin-top: 0;
      font-size: rem-calc(25);
    }

    img {
      margin-bottom: 10px;
    }
  }

  p {
    margin-bottom: 15px;
    color: $darker-gray;
    a {
      color: $blue;
      text-decoration: none;
      &:hover {
        color: $yellow;
        border-color: $yellow;
      }
    }
  }

  .summary {
    color: $darker-gray;
    font-weight: bold;
  }

  .files {
    border-top: 0;
    padding-top: 0;
  }

  aside {
    grid-column: 3 / 3;
    @include tablet-portrait-down {
      grid-column: auto;
    }
  }
}

.ie11 {
  .article {
    .article-wrapper {
      width: 68%;
    }
  }
}


.file {
  padding: 10px;
  background: $white;
  box-shadow: -1px 7px 21px -1px rgba($black,0.67);

  img {
    margin-bottom: 0;
  }
}
