.dropdown {
  position: relative;
  display: inline-block;
  .dropdown-toggle {
    min-width: 330px;
    display: inline-block;
    position: relative;
    padding: 13px 15px;
    box-shadow: -1px 7px 21px -1px rgba($light-black, 0.67);
    background: $white;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
      height: 15px;
      background: url('../img/filter-icons.png') no-repeat 0 0;
    }
    &.clicked {
      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    @include tablet-portrait-down {
      min-width: 290px;
    }
  }

  .select-all {
    margin-bottom: 20px;
    color: $blue;
    cursor: pointer;
  }

  .unselected {
    display: none;
  }
}

.dropdown-content {
  display: none;
  background: $white;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 10px;
  opacity: 0;
  box-shadow: -1px 7px 21px -1px rgba($light-black, 0.67);
}
