.xrimatodotiki-enisxisi {
  padding: 40px 0 60px 0;
  background: url('../img/3steps.jpg') no-repeat;
  background-size: cover;
  color: $white;

  .grid-container {
    max-width: rem-calc(940);
    > .block-title-2 {
      padding-left: 20px;
      padding-bottom: 40px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background: $yellow;
      }
    }
  }

  .block-title-2 {
    color: $white;
  }

  p {
    margin-bottom: 40px;
  }

  .inline-list {
    align-items: center;
  }

  .button {
    padding: 9px 30px;
    font-weight: bold;
  }

  .search-for-money {
    position: relative;
    color: $white;
    font-style: italic;
    &:hover {
      border: none;
      color: $yellow;
      text-decoration: underline;
    }
    &:after {
      content: '';
      margin-left: 5px;
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: middle;
      background: url('../img/white-arrow.png') no-repeat;
    }
  }

  .info-1 {
    padding-left: 80px;
    margin-top: 30px;
    padding-bottom: 50px;
    position: relative;
    @include tablet-portrait-down {
      padding-left: 65px;
    }
    &:before {
      content: '';
      width: 62px;
      height: 80px;
      position: absolute;
      left: -18px;
      top: 0;
      background: url('../img/blue1.png') no-repeat;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 90px;
      bottom: -12px;
      width: 4px;
      background: $yellow;
    }

    .block-title-2 {
      padding-bottom: 15px;
    }
  }
  .info-2 {
    padding-left: 80px;
    padding-bottom: 50px;
    position: relative;
    @include tablet-portrait-down {
      padding-left: 65px;
    }
    &:before {
      content: '';
      width: 69px;
      height: 80px;
      position: absolute;
      left: -18px;
      top: 0;
      background: url('../img/blue2.png') no-repeat;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 90px;
      bottom: -12px;
      width: 4px;
      background: $yellow;
    }

    .block-title-2 {
      padding-bottom: 15px;
    }
  }
  .info-3 {
    padding-left: 80px;
    position: relative;
    @include tablet-portrait-down {
      padding-left: 65px;
    }
    &:before {
      content: '';
      width: 69px;
      height: 80px;
      position: absolute;
      left: -18px;
      top: 0;
      background: url('../img/blue3.png') no-repeat;
      z-index: 2;
    }

    .block-title-2 {
      padding-bottom: 15px;
    }
  }
}

@include tablet-portrait-down {
  .xrimatodotiki-enisxisi {
    .inline-list {
      display: block;
      li {
        display: block;
        &:not(:first-child) {
          margin-top: 20px;
          margin-left: 0;
        }
      }
    }
  }
}
