*, *::before, *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  background: $white;
  color: #000;
  font-family: $font-family;
  font-size: $font-size;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a.no-decoration:hover {
  text-decoration: none;
  border-bottom:0;
}

.arthrografia, .vivliothiki, .events, .article, .contact,
.exo-xrimatodotisi, .program-page, .ebooks {
  padding: 30px 0 40px 0;
  background: $main-bg;

  .grid-container {
    max-width: rem-calc(1120);
  }

  h1 {
    margin-top: 40px;
    margin-bottom: 15px;
  }
}


aside {
  .banner {
    margin-bottom: 30px;
    img {
      max-width: initial;
    }
    a {
      &:hover {
        border: none;
      }
    }
  }
  .email {
    margin-bottom: 30px;

    .block-title-2 {
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 15px;
      color: $darker-gray;
      font-weight: bold;
    }

    .button {
      padding: 11px 20px;
      font-weight: normal;
    }
  }
}
