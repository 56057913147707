.ebooks {
  h1 {
    margin-top: 40px;
    margin-bottom: 15px;
  }
}

.ebook {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $lighten-red;
  &:last-child {
    border: none;
  }

  .ebook-title-2 {
    margin-bottom: 25px;
    color: $black;
    font-size: 25px;
  }

  .date {
    color: $black;
    font-size: 11px;
    font-weight: bold;
  }

  .summary {
    margin-top: 10px;
    margin-bottom: 15px;
    color: $darker-gray;
    font-weight: bold;
  }

  .button {
    padding: 11px 23px;
  }
}
