.breadcrumb {
  .grid-container {
    max-width: rem-calc($grid-container) !important;
  }

  li {
    position: relative;
    font-size: rem-calc(12);
    line-height: 1;
    a {
      color: $blue;
      line-height: 1;
      &:hover {
        color: $yellow;
        border: none;
        text-decoration: underline;
      }
    }
    &.active {
      color: $dark-gray;
    }
    &:not(:first-child) {
      margin-left: 5px;
    }
    &:not(:last-child) {
      padding-right: 5px;
      display: inline-block;
      vertical-align: middle;
      border-right: 1px solid $blue;
    }
  }
}
