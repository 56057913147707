img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.close-modal {
  cursor: pointer;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 15px;
  background: url('../img/close.png') no-repeat 0 -38px;
}

.clearfix {
  position: relative;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

#return-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #a3a3a3;
  width: 44px;
  height: 44px;
  display: block;
  text-decoration: none;
  border-radius: 6px;
  display: none;
  transition: all 0.3s ease;
}
#return-to-top span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 15px;
  height: 10px;
  background: url('../img/chevron-up.png') no-repeat;
}
#return-to-top:hover {
  background: rgba(#a3a3a3, 0.9);
}


.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
  padding: 0;
  box-shadow: none;
  border: 1px solid;
}
