h1, h2, h3, h4, h5, h6,
p, a, label {
  margin: 0;
  font-family: $font-family;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2em;
}

a {
  color: $light-black;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  &:hover {
    border-bottom: 1px solid $black;
  }
}


button, .button {
  display: inline-block;
  border: none;
  background: $black;
  color: $white;
  cursor: pointer;
  &:hover {
    border: none;
  }
}

.no-bullets {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    position: relative;
  }
}

.inline-list {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    position: relative;
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.block-title-2 {
  color: $black;
  font-size: rem-calc(30);
  font-weight: 100;
}

.block-title-3 {
  margin-bottom: 20px;
  padding-left: 10px;
  border-left: 4px solid $yellow;
  font-size: rem-calc(24);
  font-weight: bold;
  line-height: 1;
}

.title-with-yellow-bg {
  padding: 10px 30px;
  margin-bottom: 15px;
  color: $white;
  background: $yellow;
  font-size: rem-calc(15);
}
