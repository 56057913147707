footer {
  p {
    font-size: rem-calc(13);
  }

  a {
    color: $white;
    text-decoration: none;
    &:hover {
      border: none;
      text-decoration: underline;
    }
  }

  .widget-title {
    margin-bottom: 16px;
    color: $light-gray;
    font-size: rem-calc(19);
  }
}

.footer-top {
  padding: 30px 0 50px 0;
  background: $light-black;
  color: $white;

  img {
    @include mobile-only {
      margin-bottom: 50px;
    }
  }

  .epikoinonia {
    li {
      margin-bottom: 20px;
      padding-left: 22px;
      a {
        font-weight: 300;
      }
      &.menu-item {
        &:first-child {
          background: url('../img/contact-icons.png') no-repeat 0 5px;
          font-size: rem-calc(19);
          font-weight: 500;
          a {
            font-weight: 500;
          }
        }
        &:nth-child(2) {
          background: url('../img/contact-icons.png') no-repeat 0 -34px;
        }
        &:last-child {
          background: url('../img/contact-icons.png') no-repeat 0 -72px;
        }
      }

    }
  }

  .simantikoi-sindesmoi {
    margin-bottom: 20px;
    li {
      padding-left: 22px;
      &.menu-item {
        background: url('../img/contact-icons.png') no-repeat 0 -177px;
      }
    }
  }

  .bank-footer-menu {
    margin-top: 45px;
    li {
      margin-bottom: 10px;
    }
    a {

    }
  }
}

.footer-bottom {
  padding: 20px 0;
  p {
    font-weight: bold;
    font-size: rem-calc(13);
  }
  a {
    border: none;
    color: $dark-gray;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      border: none;
      color: $red;
    }
    &.bank-link {
      color: $dark-gray;
      &:hover {
        color: $yellow;
        text-decoration: underline;
      }
      + a {
        color: $blue;
        &:hover {
          color: $yellow;
          text-decoration: underline;
        }
      }
    }
  }
}
