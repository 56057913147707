@import 'typography/fonts';

$grid-container: 1170;

$white: #fff;
$light-black: #38404b;
$black: #0d1a34;
$lighten-black: #414042;
$dark-gray: #858585;
$darker-gray: #6e7073;
$gray: #b4b8be;

$light-gray: #eee;
$brown: #5c3910;
$red: #c72c2c;
$lighten-red: #ecd9d9;
$blue: #1e3c78;
$lighten-blue: #647393;
$yellow: #fab414;

$border-color: #d5d5d5;
$main-bg: #f2f2f2;
$font-family: 'PiraeusSans', sans-serif;
$font-size: 15px;
