@font-face {
  font-family: 'PiraeusSans';
  src: url('../fonts/PiraeusSans-Thin.eot');
  src: url('../fonts/PiraeusSans-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PiraeusSans-Thin.woff2') format('woff2'),
  url('../fonts/PiraeusSans-Thin.woff') format('woff');
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: 'PiraeusSans';
  src: url('../fonts/PiraeusSans-Light.eot');
  src: url('../fonts/PiraeusSans-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PiraeusSans-Light.woff2') format('woff2'),
  url('../fonts/PiraeusSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PiraeusSans';
  src: url('../fonts/PiraeusSans-Regular.eot');
  src: url('../fonts/PiraeusSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PiraeusSans-Regular.woff2') format('woff2'),
  url('../fonts/PiraeusSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PiraeusSans';
  src: url('../fonts/PiraeusSans-Bold.eot');
  src: url('../fonts/PiraeusSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PiraeusSans-Bold.woff2') format('woff2'),
  url('../fonts/PiraeusSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PiraeusSans';
  src: url('../fonts/PiraeusSans-Italic.eot');
  src: url('../fonts/PiraeusSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PiraeusSans-Italic.woff2') format('woff2'),
  url('../fonts/PiraeusSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
