.program-card {
  margin-bottom: 40px;
  padding: 20px;
  border-bottom: 1px solid $lighten-red;
  background: $white;
  box-shadow: -1px 7px 21px -1px rgba($dark-gray, 0.67);
  .block-title-2 {
    font-size: rem-calc(19);
  }

  .block-title-1 {
    margin-bottom: 10px;
    margin-top: 5px;
    font-size: rem-calc(18);
  }

  .content {
    color: $dark-gray;
    font-weight: bold;
    line-height: 20px;
  }
  > .inline-list {
    margin-bottom: 10px;

  }
}

.program-status {
  padding: 4px 20px 4px 20px;
  background: $yellow;
  color: $white;
  font-weight: bold;
  line-height: 1;
}

.remaining-days {
  font-weight: bold;
  span {
    font-weight: normal;
  }
}

.program-age {
  margin-bottom: 20px;
  text-align: right;
  span {
    padding: 6px 12px;
    background: $black;
    color: $white;
  }
  &.expiring {
    span {
      background: $border-color;
      color: $blue;
    }
  }
}

.program-attributes {
  margin: 30px 0;
  grid-template-columns: repeat(auto-fill, minmax(31%, auto));
  .column {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.invest-container {
  border-right: 1px solid $yellow;
  .invest-bg {
    margin-right: 5px;
    display: inline-block;
    width: 45px;
    height: 44px;
    background: url('../img/programa-stripe.png') no-repeat 0 0;
  }
  .block-title {
    color: $black;
    font-size: 15px;
    font-weight: normal;
  }
  p {
    color: $darker-gray;
  }
}

.epidotisi-container {
  border-right: 1px solid $yellow;
  .epidotisi-bg {
    margin-right: 5px;
    display: inline-block;
    width: 45px;
    height: 44px;
    background: url('../img/programa-stripe.png') no-repeat 0 -73px;
  }
  .block-title {
    color: $black;
    font-size: 15px;
    font-weight: normal;
  }
  p {
    color: $darker-gray;
  }
}

.prothesmia-container {
  .prothesmia-bg {
    margin-right: 5px;
    display: inline-block;
    width: 45px;
    height: 44px;
    background: url('../img/programa-stripe.png') no-repeat 0 -141px;
  }
  .block-title {
    color: $black;
    font-size: 15px;
    font-weight: normal;
  }
  p {
    color: $darker-gray;
  }
}

.program-footer {
  .button {
    padding: 9px 33px;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
}
