.promo-section {
  @include bg-image(90px);

  @include tablet-portrait-down {
    padding: 67px 0;
  }

  a {
    color: $blue;
    font-weight: bold;
    &:hover {
      color: $yellow;
      border: none;
      text-decoration: underline;
    }
  }
}

.promo-title {
  font-size: rem-calc(30);
  margin-bottom: 15px;
}

.promo-content {
  color: $lighten-black;
  margin-bottom: 15px;
  font-weight: bold;
}

.promo-section-2 {
  background-color: #F2B326 !important;
  @include bg-image(67px);
  @include tablet-portrait-down {
    background-position: 100% !important;
  }

  .column {
    &:nth-child(2) {
      grid-column: 2/3
    }
    &:last-child {
      grid-column: 3 / 3;
    }
    @include tablet-portrait-down {
      &:nth-child(2) {
        grid-column: auto;
      }
      &:last-child {
        grid-column: auto;
      }
    }

  }

  .promo-title, .promo-content {
    color: #023e86;
  }

  a {
    color: #023e86;
    text-decoration: underline;
    font-weight: bold;
    &:hover {
      border: none;
    }
  }
}

.ie11 {
  .promo-section-2 {
    background-position: 0 0 !important;
    .column {
      width: 25%;
      &:nth-child(2) {
        width: 50%;
      }
      &:empty { width: 16%;}
    }
  }
}

@media only screen and (max-width: 1600px) {
  .promo-section-2 {
    background-position-x: 20% !important;
  }
}

@media only screen and (max-width: 1277px) {
  .promo-section-2 {
    background-position-x: 29% !important;
  }
}

.promo-section-3 {
  padding: 60px 0;
  background: $main-bg;
  .grid-container {
    max-width: rem-calc(1080);
  }
}

.promo-section-4 {
  padding: 30px 0;
  background: $main-bg;
  @include tablet-portrait-down {
    padding: 15px 0;
  }
  .grid-container {
    max-width: rem-calc(1170);
  }
  a {
    &:hover {
      border: none;
    }
  }
}
