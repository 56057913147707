#tabs {
  width: 100%;
  padding-right: 2px;
}

#tabs li {
  padding: 10px;
  border-bottom: 5px solid $light-black;
  &:last-child {
    margin-right: 20px;
  }
  &.inactive {
    border-bottom: 5px solid $light-gray;
    a {
      color: $light-gray;
    }
    &:hover {
      a {
        color: $light-black;
        border: none;
        text-decoration: none;
      }
    }
  }
  a {
    cursor: pointer;
    font-style: italic;
    border: none;
  }
}

.popular-searches {
  padding: 20px;
  color: $light-gray;
  background: $light-black;
  text-align: center;

  h3 {
    margin-bottom: 15px;
    font-weight: bold;
    font-style: italic;
  }

  p {
    margin-bottom: 15px;
  }

  a {
    color: $light-gray;
    font-style: italic;
    &:hover {
      color: $yellow;
    }
  }
}

#tabs {
  &.bank-products {
    margin-top: 20px;
    li {
      padding: 140px 10px 10px 10px;
      margin-left: 0;
      max-width: 256px;
      width: 100%;
      border: none;
      box-shadow: -1px 11px 23px 1px rgba($light-gray,0.53);
      &.inactive {
        box-shadow: none;
      }
      a {
        display: flex;
        align-self: flex-end;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        line-height: 1;
        color: $white;
        font-size: rem-calc(30);
        text-decoration: none;
        font-style: normal;
        font-weight: 300;
      }
      &:hover {
        a {
          color: $white;
        }
      }
      &:not(:last-child) {
        margin-right: 28px;
      }
      &:first-child {
        background: $yellow;
      }
      &:nth-child(2) {
        background: $lighten-blue;
      }
      &:nth-child(3) {
        background: $brown;
      }
      &:last-child {
        margin: 0;
        background: $blue;
      }
    }

    + .tabs-content {
      margin: 20px 0;
      p {
        margin-bottom: 15px;
        color: #929292;
        font-weight: bold;
      }
      a {
        color: $blue;
        &:hover {
          border: none;
          color: $yellow;
          text-decoration: underline;
        }
      }
    }
  }
}

@include tablet-portrait-down {
  #tabs {
    &.bank-products {
      display: block;
      li {
        padding: 83px 10px 10px 10px;
        margin-bottom: 10px;
        display: inline-block;
        width: 47%;
        &:not(:last-child) {
          margin-right: 0;
        }
        &:nth-child(odd) {
          margin-right: 10px;
        }
        a {
          font-size: rem-calc(15);
        }
      }
    }
  }
}
