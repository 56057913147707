@mixin mobile-only {
  @media (max-width: 768px) { @content; }
}

@mixin tablet-portrait {
  @media (min-width: 769px) and (max-width: 850px) { @content; }
}

@mixin tablet-portrait-down {
  @media (max-width: 850px) { @content; }
}

@mixin tablet-landscape {
  @media (min-width: 851px) and (max-width: 1100px) { @content; }
}

@mixin desktop-up {
  @media (min-width: 1031px) { @content; }
}
