.ui-accordion {
  margin-bottom: 20px;
  box-shadow: -1px 7px 21px -1px rgba($dark-gray, 0.67);
  .ui-accordion-header {
    padding: 14px 18px;
    position: relative;
    border: none;
    border-radius: 0;
    background: $white;
    color: $black;
    font-weight: bold;
    font-size: 19px;
    outline: 0;
    &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      float: right;
      width: 25px;
      height: 15px;
      background: url('../img/carousel-arrows.png') 1px -21px;
    }
    &.ui-state-active {
      &:after {
        transform: rotate(180deg);
      }
    }
    span {
      display: none;
    }
  }

  .ui-accordion-content {
    position: relative;
    padding: 14px 18px;
    border: none;
    border-radius: 0;
    overflow: visible;
    &:before {
      content: '';
      position: absolute;
      left: 15px;
      top: 0;
      right: 15px;
      height: 1px;
      background: $border-color;
    }
  }
}
