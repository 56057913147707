.search-toggler {
  position: relative;
  display: inline-block;
  color: $light-black;
  cursor: pointer;
  font-style: italic;
  &:after {
    content: '';
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    background: url('../img/search-icon.png') no-repeat 0 -1px;
  }
}

.search-container {
  display: none;
  position: absolute;
  background: $white;
  top: 0;
  right: 0;
  left: auto;
  z-index: 2;
  [type="text"] {
    margin-bottom: 5px;
    box-shadow: none;
    outline: 0;
    &:focus {
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(202,202,202,0.6);
    }
  }
}

.opened {
  margin-top: 22px;
  display: block;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  @include tablet-portrait-down {
    margin-top: 0;
  }
}

@include tablet-portrait-down {
  .search-toggler {
    font-size: 0;
  }
}

@media only screen and (max-width: 600px) {
  .search-container {
    top: 55px;
    right: 0;
    left: 0;
  }
}
