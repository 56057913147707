.program-page {

  .program-page-container {
    margin-top: 50px;
  }

  .article-wrapper {
    .no-bullets {
      margin-top: 30px;
      li {
        margin-bottom: 10px;
      }
    }
  }
  p {
    color: $dark-gray;
  }

  .program-status {
    span {
      display: inline-block;
    }
  }

  .invest-container, .epidotisi-container {
    border: none;
  }

  .ui-accordion {
    box-shadow:  none;
    border-bottom: 1px solid $border-color;
    .ui-accordion-header {
      position: relative;
      padding: 15px 30px;
      background: none;
      box-shadow: none;
      @include tablet-portrait-down {
        padding-right: 30px;
        padding-left: 10px;
      }

      h3 {
        padding-left: 10px;
        border-left: 4px solid $yellow;
        font-weight: bold;
        font-size: rem-calc(24);
        @include tablet-portrait-down {
          font-size: rem-calc(15);
        }
      }

      &:after {
        position: absolute;
        top: 40%;
        right: 15px;
        transform: translate(-40%);
        @include tablet-portrait-down {
          right: 0;
          top: 30%;
          transform: translate(-30%);
        }
      }

      &.ui-state-active {
        background: $white;
        &:after {
          transform: translate(-40%) rotate(180deg);
        }
      }
    }
    .ui-accordion-content {
      padding: 14px 30px;
      p {
        color: $black;
        a {
          color: $black;
          &:hover {
            border: none;
            color: $yellow;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .program-attributes {
    .column {
      padding: 25px;
      background: $white;
      align-items: flex-start;
      justify-content: flex-start;
      box-shadow: -1px 7px 21px -1px rgba($dark-gray,0.67);
    }

    .block-title, p {
      margin-left: 10px;
      font-weight: 300;
      font-size: rem-calc(19);
    }
  }

}

.files {
  padding-top: 15px;
  border-top: 1px solid $border-color;
  img {
    margin-right: 10px;
  }
  a {
    border: none;
    &:hover {
      border: none;
    }
  }
}


.ie11 {
  .program-attributes {
    .column {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
  .files {
    .three-columns {
      .column {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}
