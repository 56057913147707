.ebook-section {
  padding: 30px 0;
  @include tablet-portrait-down {
    padding: 15px 0;
  }
  background: $lighten-blue;

  .block-title-2 {
    margin-bottom: 20px;
    color: $white;
  }
}

.ebook-section-title {
  font-size: rem-calc(50);
  color: $white;
  margin-bottom: 14px;
  line-height: 51px;
  @include tablet-portrait-down {
    font-size: rem-calc(35);
    line-height: 1;
  }
}

.ebook-section-content {
  color: $white;
}


.event-block-content {
  margin-top: 10px;
  margin-bottom: 40px;
  color: $white;
}

.read-more-link-wrapper {
  margin-top: 30px;
  .button {
    padding: 12px 31px;
    font-weight: 600;
  }
}

@include tablet-portrait-down {
  .ebook-section {
    .column {
      padding: 20px 0;
      &:last-child {
        display: none;
      }
    }
  }
}

