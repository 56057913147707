img {
  display: inline-block;
  max-width: 100%;
  height: auto; }

.close-modal {
  cursor: pointer;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 15px;
  background: url("../img/close.png") no-repeat 0 -38px; }

.clearfix {
  position: relative; }

.clearfix::after {
  content: '';
  clear: both;
  display: table; }

#return-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #a3a3a3;
  width: 44px;
  height: 44px;
  display: block;
  text-decoration: none;
  border-radius: 6px;
  display: none;
  transition: all 0.3s ease; }

#return-to-top span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 15px;
  height: 10px;
  background: url("../img/chevron-up.png") no-repeat; }

#return-to-top:hover {
  background: rgba(163, 163, 163, 0.9); }

.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
  padding: 0;
  box-shadow: none;
  border: 1px solid; }

@font-face {
  font-family: 'PiraeusSans';
  src: url("../fonts/PiraeusSans-Thin.eot");
  src: url("../fonts/PiraeusSans-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/PiraeusSans-Thin.woff2") format("woff2"), url("../fonts/PiraeusSans-Thin.woff") format("woff");
  font-weight: 100;
  font-display: swap; }

@font-face {
  font-family: 'PiraeusSans';
  src: url("../fonts/PiraeusSans-Light.eot");
  src: url("../fonts/PiraeusSans-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/PiraeusSans-Light.woff2") format("woff2"), url("../fonts/PiraeusSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'PiraeusSans';
  src: url("../fonts/PiraeusSans-Regular.eot");
  src: url("../fonts/PiraeusSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/PiraeusSans-Regular.woff2") format("woff2"), url("../fonts/PiraeusSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'PiraeusSans';
  src: url("../fonts/PiraeusSans-Bold.eot");
  src: url("../fonts/PiraeusSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/PiraeusSans-Bold.woff2") format("woff2"), url("../fonts/PiraeusSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'PiraeusSans';
  src: url("../fonts/PiraeusSans-Italic.eot");
  src: url("../fonts/PiraeusSans-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/PiraeusSans-Italic.woff2") format("woff2"), url("../fonts/PiraeusSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

h1, h2, h3, h4, h5, h6,
p, a, label {
  margin: 0;
  font-family: "PiraeusSans", sans-serif; }

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2em; }

a {
  color: #38404b;
  border-bottom: 1px solid transparent;
  text-decoration: none; }
  a:hover {
    border-bottom: 1px solid #0d1a34; }

button, .button {
  display: inline-block;
  border: none;
  background: #0d1a34;
  color: #fff;
  cursor: pointer; }
  button:hover, .button:hover {
    border: none; }

.no-bullets {
  padding: 0;
  margin: 0;
  list-style: none; }
  .no-bullets li {
    position: relative; }

.inline-list {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none; }
  .inline-list li {
    position: relative; }
    .inline-list li:not(:first-child) {
      margin-left: 15px; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.block-title-2 {
  color: #0d1a34;
  font-size: 1.875rem;
  font-weight: 100; }

.block-title-3 {
  margin-bottom: 20px;
  padding-left: 10px;
  border-left: 4px solid #fab414;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1; }

.title-with-yellow-bg {
  padding: 10px 30px;
  margin-bottom: 15px;
  color: #fff;
  background: #fab414;
  font-size: 0.9375rem; }

.bg-buttons {
  display: block;
  padding: 21px 25px;
  background: #1e3c78;
  border: none;
  font-size: 1.25rem;
  line-height: 1.3;
  font-weight: 300; }
  .bg-buttons:hover {
    border: none;
    background: #466396;
    text-decoration: none; }
  .bg-buttons span {
    padding-left: 10px;
    padding-right: 15px;
    position: relative;
    display: block;
    border: none;
    border-left: 2px solid #fab414;
    color: #fff; }
    .bg-buttons span:after {
      content: '';
      margin-top: 5px;
      position: absolute;
      right: 0;
      top: 0;
      width: 10px;
      height: 16px;
      background: url("../img/contact-icons.png") 0 -128px; }
  .bg-buttons.active {
    background: #466396; }

.btn-read-more {
  padding: 12px 31px;
  font-weight: 600; }

.grid-container {
  max-width: 73.125rem;
  margin: 0 auto;
  padding: 0 15px; }

.grid {
  display: grid;
  grid-row-gap: 15px;
  grid-column-gap: 15px;
  grid-auto-flow: row; }
  @media (max-width: 768px) {
    .grid {
      grid-row-gap: 0; } }

.subgrid {
  display: subgrid; }
  @media (max-width: 768px) {
    .subgrid {
      grid-row-gap: 0; } }

.one-column {
  grid-template-columns: repeat(auto-fill, minmax(8.33333%, auto)); }
  @media (min-width: 769px) and (max-width: 850px) {
    .one-column {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto)); } }
  @media (max-width: 768px) {
    .one-column {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto)); } }

.two-columns {
  grid-template-columns: repeat(auto-fill, minmax(15%, auto)); }
  @media (min-width: 769px) and (max-width: 850px) {
    .two-columns {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto)); } }
  @media (max-width: 768px) {
    .two-columns {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto)); } }

.three-columns {
  grid-template-columns: repeat(auto-fill, minmax(32%, auto)); }
  @media (min-width: 769px) and (max-width: 850px) {
    .three-columns {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto)); } }
  @media (max-width: 768px) {
    .three-columns {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto)); } }

.four-columns {
  grid-template-columns: repeat(auto-fill, minmax(25%, auto)); }
  @media (min-width: 769px) and (max-width: 850px) {
    .four-columns {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto)); } }
  @media (max-width: 768px) {
    .four-columns {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto)); } }

.six-columns {
  grid-template-columns: repeat(auto-fill, minmax(49%, auto)); }
  @media (min-width: 769px) and (max-width: 850px) {
    .six-columns {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto)); } }
  @media (max-width: 768px) {
    .six-columns {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto)); } }

.seven-columns {
  grid-template-columns: repeat(auto-fill, minmax(58.33333%, auto)); }
  @media (min-width: 769px) and (max-width: 850px) {
    .seven-columns {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto)); } }
  @media (max-width: 768px) {
    .seven-columns {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto)); } }

.align-baseline {
  align-items: baseline; }

.align-center {
  align-items: center; }

.justify-center {
  justify-content: center; }

.justify-right {
  justify-content: flex-end; }

.ie11 .grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.ie11 .one-column .column {
  width: 8.3333%;
  padding: 0 15px; }
  @media (min-width: 769px) and (max-width: 850px) {
    .ie11 .one-column .column {
      width: 100%; } }
  @media (max-width: 768px) {
    .ie11 .one-column .column {
      width: 100%; } }

.ie11 .two-columns .column {
  width: 15%;
  padding: 0 15px; }
  @media (min-width: 769px) and (max-width: 850px) {
    .ie11 .two-columns .column {
      width: 100%; } }
  @media (max-width: 768px) {
    .ie11 .two-columns .column {
      width: 100%; } }

.ie11 .three-columns .column {
  width: 32%;
  padding: 0 15px; }
  @media (min-width: 769px) and (max-width: 850px) {
    .ie11 .three-columns .column {
      width: 100%; } }
  @media (max-width: 768px) {
    .ie11 .three-columns .column {
      width: 100%; } }

.ie11 .four-columns .column {
  width: 25%;
  padding: 0 15px; }
  @media (min-width: 769px) and (max-width: 850px) {
    .ie11 .four-columns .column {
      width: 100%; } }
  @media (max-width: 768px) {
    .ie11 .four-columns .column {
      width: 100%; } }

.ie11 .six-columns .column {
  width: 49%;
  padding: 0 15px; }
  @media (min-width: 769px) and (max-width: 850px) {
    .ie11 .six-columns .column {
      width: 100%; } }
  @media (max-width: 768px) {
    .ie11 .six-columns .column {
      width: 100%; } }

.ie11 .seven-columns .column {
  width: 58.33333%;
  padding: 0 15px; }
  @media (min-width: 769px) and (max-width: 850px) {
    .ie11 .seven-columns .column {
      width: 100%; } }
  @media (max-width: 768px) {
    .ie11 .seven-columns .column {
      width: 100%; } }

#tabs {
  width: 100%;
  padding-right: 2px; }

#tabs li {
  padding: 10px;
  border-bottom: 5px solid #38404b; }
  #tabs li:last-child {
    margin-right: 20px; }
  #tabs li.inactive {
    border-bottom: 5px solid #eee; }
    #tabs li.inactive a {
      color: #eee; }
    #tabs li.inactive:hover a {
      color: #38404b;
      border: none;
      text-decoration: none; }
  #tabs li a {
    cursor: pointer;
    font-style: italic;
    border: none; }

.popular-searches {
  padding: 20px;
  color: #eee;
  background: #38404b;
  text-align: center; }
  .popular-searches h3 {
    margin-bottom: 15px;
    font-weight: bold;
    font-style: italic; }
  .popular-searches p {
    margin-bottom: 15px; }
  .popular-searches a {
    color: #eee;
    font-style: italic; }
    .popular-searches a:hover {
      color: #fab414; }

#tabs.bank-products {
  margin-top: 20px; }
  #tabs.bank-products li {
    padding: 140px 10px 10px 10px;
    margin-left: 0;
    max-width: 256px;
    width: 100%;
    border: none;
    box-shadow: -1px 11px 23px 1px rgba(238, 238, 238, 0.53); }
    #tabs.bank-products li.inactive {
      box-shadow: none; }
    #tabs.bank-products li a {
      display: flex;
      align-self: flex-end;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      line-height: 1;
      color: #fff;
      font-size: 1.875rem;
      text-decoration: none;
      font-style: normal;
      font-weight: 300; }
    #tabs.bank-products li:hover a {
      color: #fff; }
    #tabs.bank-products li:not(:last-child) {
      margin-right: 28px; }
    #tabs.bank-products li:first-child {
      background: #fab414; }
    #tabs.bank-products li:nth-child(2) {
      background: #647393; }
    #tabs.bank-products li:nth-child(3) {
      background: #5c3910; }
    #tabs.bank-products li:last-child {
      margin: 0;
      background: #1e3c78; }
  #tabs.bank-products + .tabs-content {
    margin: 20px 0; }
    #tabs.bank-products + .tabs-content p {
      margin-bottom: 15px;
      color: #929292;
      font-weight: bold; }
    #tabs.bank-products + .tabs-content a {
      color: #1e3c78; }
      #tabs.bank-products + .tabs-content a:hover {
        border: none;
        color: #fab414;
        text-decoration: underline; }

@media (max-width: 850px) {
  #tabs.bank-products {
    display: block; }
    #tabs.bank-products li {
      padding: 83px 10px 10px 10px;
      margin-bottom: 10px;
      display: inline-block;
      width: 47%; }
      #tabs.bank-products li:not(:last-child) {
        margin-right: 0; }
      #tabs.bank-products li:nth-child(odd) {
        margin-right: 10px; }
      #tabs.bank-products li a {
        font-size: 0.9375rem; } }

.ui-accordion {
  margin-bottom: 20px;
  box-shadow: -1px 7px 21px -1px rgba(133, 133, 133, 0.67); }
  .ui-accordion .ui-accordion-header {
    padding: 14px 18px;
    position: relative;
    border: none;
    border-radius: 0;
    background: #fff;
    color: #0d1a34;
    font-weight: bold;
    font-size: 19px;
    outline: 0; }
    .ui-accordion .ui-accordion-header:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      float: right;
      width: 25px;
      height: 15px;
      background: url("../img/carousel-arrows.png") 1px -21px; }
    .ui-accordion .ui-accordion-header.ui-state-active:after {
      transform: rotate(180deg); }
    .ui-accordion .ui-accordion-header span {
      display: none; }
  .ui-accordion .ui-accordion-content {
    position: relative;
    padding: 14px 18px;
    border: none;
    border-radius: 0;
    overflow: visible; }
    .ui-accordion .ui-accordion-content:before {
      content: '';
      position: absolute;
      left: 15px;
      top: 0;
      right: 15px;
      height: 1px;
      background: #d5d5d5; }

/* Customize the label (the checkbox-container) */
.checkbox-container {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-weight: bold; }

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer; }

/* Customize the label (the radio-container) */
.radio-container {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-weight: bold; }

/* Hide the browser's default checkbox */
.radio-container input {
  position: absolute;
  top: 4px;
  left: 0;
  cursor: pointer; }

.search-checkbox-container {
  display: none;
  position: absolute;
  top: -10px;
  background: #fff;
  opacity: 0; }

.search-form-section {
  padding: 20px;
  background: #fff; }

.search-form {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #eee; }
  .search-form input {
    width: 330px;
    padding: 8px 5px;
    border: 0;
    border-top: 1px solid #eee;
    background: #fff; }
  .search-form button {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    border: 0;
    box-shadow: none;
    background: url("../img/search-icon.png") no-repeat 0 -1px;
    outline: 0;
    text-indent: -9999px; }

.datepicker-wrapper {
  position: relative; }
  .datepicker-wrapper:after {
    content: '';
    width: 23px;
    height: 22px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    background: url("../img/calendar.png") no-repeat; }

.hasDatepicker {
  cursor: pointer;
  padding: 18px 25px;
  border: none;
  box-shadow: -1px 7px 21px -1px rgba(133, 133, 133, 0.67); }
  .hasDatepicker:focus {
    outline: 0; }

select {
  max-width: 100%;
  padding: 14px 25px;
  border: none;
  box-shadow: -1px 7px 21px -1px rgba(133, 133, 133, 0.67); }
  select:focus {
    outline: 0; }

[type="text"], [type="email"], [type="number"] {
  padding: 15px;
  border: none;
  background: #fff;
  color: #0d1a34;
  box-shadow: -1px 7px 21px -1px rgba(133, 133, 133, 0.67); }

textarea {
  outline: none;
  resize: none;
  max-width: 100%;
  border: none;
  box-shadow: -1px 7px 21px -1px rgba(133, 133, 133, 0.67);
  color: #0d1a34; }

.input-group {
  margin-bottom: 70px;
  display: table; }
  .input-group .input {
    display: table-cell; }
  .input-group input[type="search"] {
    padding: 12px 24px;
    color: #555;
    background-color: #fff;
    border: 1px solid #d5d5d5;
    border-radius: 0;
    box-shadow: inset 0 1px 1px rgba(13, 26, 52, 0.075);
    font-size: 14px;
    line-height: 1;
    outline: 0; }
    .input-group input[type="search"]:focus {
      box-shadow: inset 0 1px 1px rgba(13, 26, 52, 0.075), 0 0 8px rgba(133, 133, 133, 0.6); }
  .input-group input[type="submit"] {
    background: #1e3c78;
    color: #fff;
    padding: 15px;
    border: 0;
    line-height: 1;
    cursor: pointer; }

.dropdown {
  position: relative;
  display: inline-block; }
  .dropdown .dropdown-toggle {
    min-width: 330px;
    display: inline-block;
    position: relative;
    padding: 13px 15px;
    box-shadow: -1px 7px 21px -1px rgba(56, 64, 75, 0.67);
    background: #fff;
    cursor: pointer; }
    .dropdown .dropdown-toggle:after {
      content: '';
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
      height: 15px;
      background: url("../img/filter-icons.png") no-repeat 0 0; }
    .dropdown .dropdown-toggle.clicked:after {
      transform: translateY(-50%) rotate(180deg); }
    @media (max-width: 850px) {
      .dropdown .dropdown-toggle {
        min-width: 290px; } }
  .dropdown .select-all {
    margin-bottom: 20px;
    color: #1e3c78;
    cursor: pointer; }
  .dropdown .unselected {
    display: none; }

.dropdown-content {
  display: none;
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 10px;
  opacity: 0;
  box-shadow: -1px 7px 21px -1px rgba(56, 64, 75, 0.67); }

.card {
  position: relative;
  box-shadow: -1px 11px 23px 1px rgba(133, 133, 133, 0.53);
  background: #fff; }
  @media (max-width: 850px) {
    .card {
      margin-bottom: 20px; } }
  .card img {
    display: block;
    margin: 0 auto; }

.grid .card {
  height: 100%; }

.link {
  text-align: right;
  position: absolute;
  bottom: 10px;
  right: 10px; }
  .link a {
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url("../img/round-arrow.png") no-repeat; }
    .link a:hover {
      border: none; }

.card-category {
  color: #5c3910;
  font-size: 0.6875rem;
  text-transform: uppercase; }

.date-wrapper {
  text-align: right; }
  .date-wrapper .date {
    color: #b4b8be;
    font-size: 0.6875rem;
    font-weight: bold; }

.card-title {
  margin-top: 10px;
  font-size: 1.1875rem;
  line-height: 20px; }
  .card-title a {
    color: #1e3c78; }
    .card-title a:hover {
      border: none;
      color: #fab414;
      text-decoration: underline; }

.card-content {
  background: #fff;
  padding: 10px; }
  .card-content .six-columns {
    grid-template-columns: repeat(auto-fill, minmax(31%, auto)); }
  .card-content .content {
    margin-bottom: 40px; }
    @media (max-width: 850px) {
      .card-content .content {
        margin-bottom: 10px; } }
  .card-content .body {
    margin-top: 10px;
    position: relative;
    padding-left: 20px; }
    .card-content .body:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 35px;
      background: #1e3c78; }

.bg-card {
  padding: 20px;
  background: #eee; }
  .bg-card .card-content {
    padding: 0;
    background: #eee; }
  .bg-card p {
    color: #858585; }
  .bg-card .body {
    margin-bottom: 0; }
  .bg-card .link a {
    background: none;
    width: auto;
    height: auto;
    border: none;
    color: #858585;
    font-style: italic;
    text-decoration: underline; }
    .bg-card .link a:hover {
      border: none; }

.file-card .three-columns {
  grid-template-columns: repeat(auto-fill, minmax(17%, auto)); }

.file-card .column:last-child {
  grid-column: 2 / 5; }

.file-card .card-category {
  color: #466396;
  text-transform: uppercase; }

.file-card .card-title {
  font-size: 0.9375rem; }

.file-card .pdf {
  width: 47px;
  height: 56px;
  background: url("../img/file.png") no-repeat;
  display: inline-block; }

.file-card .file-size {
  font-size: 11px;
  color: #6e7073; }

.file-card .file {
  width: 47px;
  height: 55px;
  background: url("../img/file.png") no-repeat -99px 0;
  display: inline-block; }

.file-card .file-download {
  text-align: right; }
  .file-card .file-download a {
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url("../img/download-file.png") no-repeat; }
    .file-card .file-download a:hover {
      border: none; }

.file-card .date {
  color: #858585;
  font-size: 0.6875rem;
  font-weight: bold; }

.event-card .date-wrapper {
  text-align: left; }

.event-card .card-content .body {
  padding: 0; }
  .event-card .card-content .body:before {
    display: none; }

.ie11 .card {
  margin-bottom: 20px; }

.ie11 .file-card .three-columns > .column:last-child {
  width: 68%; }

.ie11 .event-card .column {
  width: 100%; }

.newsletter-block {
  margin-top: 30px;
  padding: 20px 0;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important; }
  .newsletter-block .three-columns {
    grid-template-columns: repeat(auto-fill, minmax(33%, auto)); }
    @media (max-width: 850px) {
      .newsletter-block .three-columns {
        grid-template-columns: repeat(auto-fill, minmax(100%, auto)); } }
  .newsletter-block .block-title-2 {
    margin-bottom: 30px; }
  .newsletter-block .button-wrapper {
    text-align: right; }
    @media (max-width: 850px) {
      .newsletter-block .button-wrapper {
        margin-top: 20px;
        text-align: left; } }
  .newsletter-block .button {
    padding: 15px 19px; }

.ie11 .newsletter-block .column {
  width: 48%; }

.search-toggler {
  position: relative;
  display: inline-block;
  color: #38404b;
  cursor: pointer;
  font-style: italic; }
  .search-toggler:after {
    content: '';
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    background: url("../img/search-icon.png") no-repeat 0 -1px; }

.search-container {
  display: none;
  position: absolute;
  background: #fff;
  top: 0;
  right: 0;
  left: auto;
  z-index: 2; }
  .search-container [type="text"] {
    margin-bottom: 5px;
    box-shadow: none;
    outline: 0; }
    .search-container [type="text"]:focus {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(202, 202, 202, 0.6); }

.opened {
  margin-top: 22px;
  display: block;
  opacity: 1;
  transition: opacity 0.3s ease-in-out; }
  @media (max-width: 850px) {
    .opened {
      margin-top: 0; } }

@media (max-width: 850px) {
  .search-toggler {
    font-size: 0; } }

@media only screen and (max-width: 600px) {
  .search-container {
    top: 55px;
    right: 0;
    left: 0; } }

.welcome-section {
  position: relative;
  z-index: 2;
  margin-top: 50px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  box-shadow: 0 15px 25px -8px #858585; }

.welcome-message {
  padding-top: 30px;
  padding-bottom: 40px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee; }
  .welcome-message article {
    max-width: 57.5rem;
    text-align: center; }
  .welcome-message .title {
    margin-bottom: 28px;
    color: #1e3c78;
    font-size: 1.75rem;
    font-weight: 300; }

.welcome-points {
  margin-top: 20px; }
  .welcome-points article {
    text-align: center; }
  .welcome-points .title {
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 0.9375rem;
    font-weight: bold; }

@media (max-width: 768px) {
  .welcome-message {
    padding-top: 20px;
    padding-bottom: 20px; }
  .welcome-points {
    max-width: 13.75rem; }
    .welcome-points .column {
      margin-bottom: 20px; } }

.search-program {
  margin-top: -30px;
  padding-top: 50px;
  padding-bottom: 30px;
  background: #eee; }
  .search-program .form-container .inline-list li {
    background: #fff; }

.form-container {
  margin-top: 30px; }
  .form-container .inline-list li {
    max-width: 182px;
    width: 100%;
    box-shadow: -1px 7px 21px -1px rgba(56, 64, 75, 0.67); }
    .form-container .inline-list li:not(:first-child) {
      margin-left: 10px; }
  .form-container .radio-buttons-container {
    margin-bottom: 40px; }
    .form-container .radio-buttons-container.activity-container {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto)); }
    .form-container .radio-buttons-container .dropdown .dropdown-toggle {
      padding: 0;
      min-width: auto;
      box-shadow: none; }
      .form-container .radio-buttons-container .dropdown .dropdown-toggle:after {
        bottom: -22px;
        top: auto; }
        @media (max-width: 850px) {
          .form-container .radio-buttons-container .dropdown .dropdown-toggle:after {
            bottom: 0; } }
      .form-container .radio-buttons-container .dropdown .dropdown-toggle p {
        min-height: 108px;
        padding: 10px;
        margin-bottom: 60px;
        font-weight: bold; }
        @media (max-width: 850px) {
          .form-container .radio-buttons-container .dropdown .dropdown-toggle p {
            margin-bottom: 44px; } }
    .form-container .radio-buttons-container .dropdown .dropdown-content {
      left: auto;
      width: 750px; }
      .form-container .radio-buttons-container .dropdown .dropdown-content .six-columns {
        grid-template-columns: repeat(auto-fill, minmax(48%, auto)); }
    .form-container .radio-buttons-container .dropdown .radio-container {
      background: #fff;
      padding-left: 25px; }
      .form-container .radio-buttons-container .dropdown .radio-container p {
        min-height: 64px;
        padding: 10px;
        margin-bottom: 60px; }
      .form-container .radio-buttons-container .dropdown .radio-container input {
        position: absolute;
        margin: 0; }
  .form-container .form-actions {
    margin-top: 50px;
    text-align: center; }
    .form-container .form-actions button {
      padding: 16px 38px; }

.radio-container {
  background: #fff;
  padding-left: 0; }
  .radio-container p {
    padding: 10px;
    margin-bottom: 60px; }
  .radio-container input {
    position: static;
    display: block;
    margin: 10px; }

.radio-buttons-container .radio-container p {
  min-height: 108px; }

.type-of-company .six-columns {
  grid-template-columns: repeat(auto-fill, minmax(48%, auto)); }

@media (min-width: 769px) and (max-width: 850px) {
  .form-container .activity-container .inline-list {
    display: block; }
    .form-container .activity-container .inline-list li {
      margin-bottom: 20px;
      width: 33.333%;
      display: inline-block;
      float: left; }
      .form-container .activity-container .inline-list li:not(:first-child) {
        margin-left: 0;
        margin-right: 15px; }
      .form-container .activity-container .inline-list li:first-child {
        margin-left: 0;
        margin-right: 15px; }
      .form-container .activity-container .inline-list li .radio-container {
        margin-bottom: 0; } }

@media (max-width: 850px) {
  .form-container .radio-buttons-container .dropdown .dropdown-content {
    max-width: 690px;
    width: 100%; }
  .type-of-company {
    margin-top: 40px; } }

@media (max-width: 768px) {
  .form-container .activity-container .inline-list {
    display: block; }
    .form-container .activity-container .inline-list li {
      margin-bottom: 20px;
      float: left;
      width: 45%;
      display: inline-block; }
      .form-container .activity-container .inline-list li:not(:first-child) {
        margin-left: 0;
        margin-right: 14px; }
      .form-container .activity-container .inline-list li:first-child {
        margin-left: 0;
        margin-right: 15px; }
      .form-container .activity-container .inline-list li .radio-container {
        margin-bottom: 0; } }

.arthrografia-vivliothiki {
  padding: 30px 0; }
  @media (max-width: 850px) {
    .arthrografia-vivliothiki {
      padding: 15px 0; } }
  .arthrografia-vivliothiki .grid {
    grid-column-gap: 60px; }
    .arthrografia-vivliothiki .grid .card {
      height: auto; }

.ie11 .arthrografia-vivliothiki .column {
  width: 72%; }
  .ie11 .arthrografia-vivliothiki .column.vivliothiki-wrapper {
    width: 25%;
    margin-left: 15px; }

.arthrografia-wrapper {
  grid-column: 1 / 3; }
  @media (max-width: 850px) {
    .arthrografia-wrapper {
      grid-column: auto; } }

.vivliothiki-wrapper {
  grid-column: 3 / 4; }
  @media (max-width: 850px) {
    .vivliothiki-wrapper {
      margin-bottom: 20px;
      grid-column: auto; } }
  .vivliothiki-wrapper ul {
    position: relative; }
    .vivliothiki-wrapper ul:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      width: 1px;
      background: #d5d5d5; }
  .vivliothiki-wrapper li {
    position: relative;
    padding-left: 10px;
    padding-bottom: 20px;
    margin-bottom: 20px; }
    .vivliothiki-wrapper li:before {
      content: '';
      position: absolute;
      width: 108px;
      height: 1px;
      background: #d5d5d5;
      left: -30px;
      bottom: 0; }
  .vivliothiki-wrapper a {
    color: #0d1a34;
    font-weight: bold; }
  .vivliothiki-wrapper .grid .card {
    height: auto; }

.read-more-links {
  margin-top: 30px;
  font-size: 0.75rem;
  font-style: italic; }
  .read-more-links li:not(:first-child) {
    padding-right: 10px;
    margin-right: 5px;
    margin-left: 5px;
    border-right: 1px solid #fab414; }
  .read-more-links li:last-child {
    border-right: none; }
  .read-more-links a {
    color: #1e3c78;
    line-height: 1; }

@media (max-width: 850px) {
  .read-more-links {
    margin-bottom: 20px;
    display: block; }
    .read-more-links li {
      margin-top: 10px;
      display: inline-block; }
      .read-more-links li:first-child {
        display: block; } }

.ebook-section {
  padding: 30px 0;
  background: #647393; }
  @media (max-width: 850px) {
    .ebook-section {
      padding: 15px 0; } }
  .ebook-section .block-title-2 {
    margin-bottom: 20px;
    color: #fff; }

.ebook-section-title {
  font-size: 3.125rem;
  color: #fff;
  margin-bottom: 14px;
  line-height: 51px; }
  @media (max-width: 850px) {
    .ebook-section-title {
      font-size: 2.1875rem;
      line-height: 1; } }

.ebook-section-content {
  color: #fff; }

.event-block-content {
  margin-top: 10px;
  margin-bottom: 40px;
  color: #fff; }

.read-more-link-wrapper {
  margin-top: 30px; }
  .read-more-link-wrapper .button {
    padding: 12px 31px;
    font-weight: 600; }

@media (max-width: 850px) {
  .ebook-section .column {
    padding: 20px 0; }
    .ebook-section .column:last-child {
      display: none; } }

.events-section {
  padding: 30px 0;
  background: url("../img/events-bg.png") repeat; }
  @media (max-width: 850px) {
    .events-section {
      padding: 15px 0; } }
  .events-section .grid {
    grid-column-gap: 60px; }
    @media (max-width: 850px) {
      .events-section .grid {
        grid-column: auto; } }
  .events-section .block-title-2 {
    margin-bottom: 30px; }

.ie11 .events-section .column.events-read-more {
  width: 23%; }

.ie11 .events-section .column.events-section-wrapper {
  width: 73%; }
  .ie11 .events-section .column.events-section-wrapper .column {
    width: 73%; }
    .ie11 .events-section .column.events-section-wrapper .column:first-child {
      width: 23%; }

.events-section-wrapper {
  grid-column: 1 / 3; }
  @media (max-width: 850px) {
    .events-section-wrapper {
      grid-column: auto; } }
  .events-section-wrapper .two-columns {
    grid-template-columns: repeat(auto-fill, minmax(21%, auto)); }
    @media (max-width: 850px) {
      .events-section-wrapper .two-columns {
        grid-template-columns: repeat(auto-fill, minmax(100%, auto)); } }
  .events-section-wrapper .grid .column {
    grid-column: 1 / 1; }
    .events-section-wrapper .grid .column:last-child {
      grid-column: 2 / 7; }
    @media (max-width: 850px) {
      .events-section-wrapper .grid .column {
        grid-column: auto;
        margin-bottom: 20px; }
        .events-section-wrapper .grid .column:last-child {
          grid-column: auto; }
        .events-section-wrapper .grid .column:first-child {
          display: none; } }
  .events-section-wrapper .date {
    color: #858585;
    font-weight: bold; }

.all-events-block {
  background: #fff;
  position: relative;
  padding: 30px 10px 0 30px; }
  .all-events-block:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 15px;
    width: 4px;
    background: #fab414; }
  .all-events-block p {
    color: #38404b;
    font-size: 1.1875rem;
    font-style: italic; }
    .all-events-block p:not(.link) {
      margin-bottom: 55px; }

.event-block-title {
  font-size: 2rem;
  line-height: 35px; }
  .event-block-title a {
    color: #0d1a34;
    text-decoration: none; }
    .event-block-title a:hover {
      border: none; }

.event-block-content {
  color: #858585;
  font-weight: bold; }

.events-read-more {
  grid-column: 3 / 4; }
  @media (max-width: 850px) {
    .events-read-more {
      grid-column: auto; } }
  .events-read-more ul {
    margin-top: 30px;
    padding: 0 30px; }
  .events-read-more li {
    position: relative;
    padding-left: 10px;
    padding-bottom: 20px;
    margin-bottom: 20px; }
    .events-read-more li:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 10px;
      top: -10px;
      width: 1px;
      background: #ecd9d9; }
    .events-read-more li:not(:last-child):before {
      content: '';
      position: absolute;
      left: -30px;
      right: 0;
      height: 1px;
      background: #ecd9d9;
      bottom: 0; }
    .events-read-more li .date {
      color: #0d1a34;
      font-weight: bold; }
    .events-read-more li a {
      color: #1e3c78;
      font-weight: bold; }
      .events-read-more li a:hover {
        border: none;
        color: #fab414;
        text-decoration: underline; }

.view-all-events {
  margin-top: 20px;
  display: none;
  position: relative; }
  .view-all-events a {
    color: #1e3c78;
    font-weight: bold;
    font-style: italic;
    text-decoration: underline; }
    .view-all-events a:after {
      content: '';
      margin-left: 10px;
      width: 23px;
      height: 23px;
      display: inline-block;
      vertical-align: middle;
      background: url("../img/more-events-arrow.png") no-repeat; }

@media (max-width: 850px) {
  .view-all-events {
    display: block; } }

.promo-section {
  padding: 90px 0;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important; }
  @media (max-width: 850px) {
    .promo-section {
      padding: 67px 0; } }
  .promo-section a {
    color: #1e3c78;
    font-weight: bold; }
    .promo-section a:hover {
      color: #fab414;
      border: none;
      text-decoration: underline; }

.promo-title {
  font-size: 1.875rem;
  margin-bottom: 15px; }

.promo-content {
  color: #414042;
  margin-bottom: 15px;
  font-weight: bold; }

.promo-section-2 {
  background-color: #F2B326 !important;
  padding: 67px 0;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important; }
  @media (max-width: 850px) {
    .promo-section-2 {
      background-position: 100% !important; } }
  .promo-section-2 .column:nth-child(2) {
    grid-column: 2/3; }
  .promo-section-2 .column:last-child {
    grid-column: 3 / 3; }
  @media (max-width: 850px) {
    .promo-section-2 .column:nth-child(2) {
      grid-column: auto; }
    .promo-section-2 .column:last-child {
      grid-column: auto; } }
  .promo-section-2 .promo-title, .promo-section-2 .promo-content {
    color: #023e86; }
  .promo-section-2 a {
    color: #023e86;
    text-decoration: underline;
    font-weight: bold; }
    .promo-section-2 a:hover {
      border: none; }

.ie11 .promo-section-2 {
  background-position: 0 0 !important; }
  .ie11 .promo-section-2 .column {
    width: 25%; }
    .ie11 .promo-section-2 .column:nth-child(2) {
      width: 50%; }
    .ie11 .promo-section-2 .column:empty {
      width: 16%; }

@media only screen and (max-width: 1600px) {
  .promo-section-2 {
    background-position-x: 20% !important; } }

@media only screen and (max-width: 1277px) {
  .promo-section-2 {
    background-position-x: 29% !important; } }

.promo-section-3 {
  padding: 60px 0;
  background: #f2f2f2; }
  .promo-section-3 .grid-container {
    max-width: 67.5rem; }

.promo-section-4 {
  padding: 30px 0;
  background: #f2f2f2; }
  @media (max-width: 850px) {
    .promo-section-4 {
      padding: 15px 0; } }
  .promo-section-4 .grid-container {
    max-width: 73.125rem; }
  .promo-section-4 a:hover {
    border: none; }

.homepage-news {
  padding: 30px 0; }
  @media (max-width: 850px) {
    .homepage-news {
      padding: 15px 0; } }
  .homepage-news .block-title-2 {
    margin-bottom: 30px; }

.filter-container {
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d5d5d5; }

.filter-title {
  margin-bottom: 5px;
  color: #0d1a34;
  font-size: 0.9375rem;
  font-weight: bold; }

@media (max-width: 850px) {
  .filter-container .inline-list {
    display: block; }
    .filter-container .inline-list li {
      margin: 0 0 15px 0;
      display: inline-block;
      text-align: left; } }

.ie11 .filters .four-columns .column {
  width: 36%; }
  .ie11 .filters .four-columns .column:last-child {
    width: 50%; }

.pagination {
  margin-top: 40px; }
  .pagination a {
    color: #858585;
    font-size: 0.75rem; }
    .pagination a:hover {
      border: none; }
  .pagination .active a {
    padding: 9px;
    background: #0d1a34;
    color: #fff; }

.social-media-share {
  margin: 40px 0 30px 0; }

.download-link {
  padding: 14px 17px;
  position: relative;
  background: #0d1a34;
  font-weight: bold; }
  .download-link:after {
    content: '';
    margin-left: 10px;
    width: 22px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    background: url("../img/blue-dl.png") no-repeat; }
  .download-link:hover {
    border: none;
    color: #fab414;
    text-decoration: underline; }

.share-button {
  outline: 0;
  width: 50px;
  height: 50px;
  background: transparent;
  text-indent: -9999px;
  background: url("../img/share-icons.png") no-repeat;
  background-size: cover;
  box-shadow: -1px 7px 21px -1px rgba(133, 133, 133, 0.67); }
  .share-button.fb-share {
    background-position: 0 -72px; }
  .share-button.twitter-share {
    background-position: 0 -146px; }
  .share-button.linkedin-share {
    background-position: 0 -222px; }
  .share-button.print-share {
    background-position: 0 0; }

@media (max-width: 768px) {
  .social-media-share .inline-list {
    display: block;
    text-align: right; }
    .social-media-share .inline-list li {
      display: inline-block;
      vertical-align: middle; }
      .social-media-share .inline-list li:last-child {
        display: none; } }

.see-more {
  margin-top: 20px; }
  .see-more .block-title-2 {
    margin-bottom: 20px; }

.contact [type="text"], .contact [type="email"],
.contact select, .contact textarea {
  max-width: 500px;
  width: 100%; }

.contact .grid-container.contact-container {
  margin-top: 50px;
  max-width: 73.125rem; }

.contact .grid-container .grid {
  grid-column-gap: 25px; }

.contact .contact-wrapper {
  grid-column: 1 / 3; }
  @media (max-width: 850px) {
    .contact .contact-wrapper {
      grid-column: auto; } }
  .contact .contact-wrapper .block-title-2 {
    margin-bottom: 40px; }

.contact aside {
  grid-column: 3 / 3; }
  @media (max-width: 850px) {
    .contact aside {
      margin-top: 40px;
      grid-column: auto; } }

.klados-epixeirisis .opened {
  margin-top: 0; }

.form-container .klados-epixeirisis .radio-buttons-container .radio-container p {
  margin-bottom: 35px; }

.form-container .klados-epixeirisis .radio-buttons-container .dropdown-toggle p {
  margin-bottom: 83px; }
  @media (max-width: 850px) {
    .form-container .klados-epixeirisis .radio-buttons-container .dropdown-toggle p {
      margin-bottom: 60px; } }

.form-container .klados-epixeirisis .radio-buttons-container .dropdown-toggle:after {
  bottom: 0; }

.form-container h6 {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.1875rem; }

.form-container .poso-ependisis {
  margin-bottom: 35px; }

.form-container .perifereia {
  margin-bottom: 35px; }

.form-container .edra-epixeirisis {
  margin-bottom: 35px; }

.form-container .info-about-program {
  margin-bottom: 35px; }
  .form-container .info-about-program .checkbox-container {
    font-weight: normal; }

.form-container .company-name, .form-container .other-question,
.form-container .representative-name, .form-container .representative-surname,
.form-container .representative-email, .form-container .telephone {
  margin-bottom: 35px; }

.form-container .gdpr {
  margin-bottom: 35px; }
  .form-container .gdpr p {
    margin-bottom: 10px;
    color: #0d1a34; }
    .form-container .gdpr p a {
      color: #1e3c78; }
      .form-container .gdpr p a:hover {
        border: none;
        color: #fab414;
        text-decoration: underline; }
  .form-container .gdpr label {
    font-weight: normal; }

.form-container .form-submit .button {
  padding: 12px 47px;
  font-size: 0.875rem; }

.search-for {
  margin-bottom: 35px; }
  .search-for .inline-list li {
    width: auto;
    max-width: initial;
    box-shadow: none; }
    .search-for .inline-list li:not(:first-child) {
      margin-left: 20px; }
  .search-for .radio-container {
    background: transparent; }
    .search-for .radio-container input {
      margin: 0;
      display: inline-block; }
    .search-for .radio-container p {
      margin: 0;
      padding: 0;
      display: inline-block;
      font-weight: 300;
      font-size: 20px; }

.company-size {
  margin-bottom: 35px; }
  .company-size .four-columns {
    grid-template-columns: repeat(auto-fill, minmax(22%, auto)); }
    @media (max-width: 850px) {
      .company-size .four-columns {
        grid-template-columns: repeat(auto-fill, minmax(100%, auto)); } }
  .company-size .grid {
    grid-column-gap: 15px; }
  .company-size .radio-container {
    padding: 10px;
    width: 100%; }
    .company-size .radio-container input {
      margin: 0 0 10px auto; }
    .company-size .radio-container .comp-size {
      float: left;
      width: 24px;
      height: 25px; }
    .company-size .radio-container .info {
      float: right;
      width: 18px;
      height: 18px;
      background: url("../img/info-icon.png") no-repeat; }
    .company-size .radio-container p {
      padding: 0;
      margin: 10px 0 0 0;
      display: inline-block;
      color: #0d1a34;
      font-weight: 300; }
  .company-size .radio:nth-child(1) .radio-container .comp-size {
    background: url("../img/company-size.png") no-repeat 0 -5px; }
  .company-size .radio:nth-child(2) .radio-container .comp-size {
    width: 50px;
    height: 25px;
    background: url("../img/company-size.png") no-repeat 0 -137px; }
  .company-size .radio:nth-child(3) .radio-container .comp-size {
    width: 77px;
    height: 25px;
    background: url("../img/company-size.png") no-repeat 0 -277px; }
  .company-size .radio:nth-child(4) .radio-container .comp-size {
    width: 100px;
    height: 25px;
    background: url("../img/company-size.png") no-repeat 0 -444px; }

@media (max-width: 850px) {
  .search-for .inline-list {
    display: block; }
    .search-for .inline-list li {
      display: block; }
      .search-for .inline-list li:not(:first-child) {
        margin-top: 5px;
        margin-left: 0; }
  .radio-container {
    margin-bottom: 20px; }
    .radio-container p {
      margin-bottom: 20px; }
  .form-container .radio-buttons-container {
    grid-template-columns: repeat(auto-fill, minmax(45%, auto)); } }

.ie11 .three-columns .contact-wrapper {
  width: 68%; }

.program-card {
  margin-bottom: 40px;
  padding: 20px;
  border-bottom: 1px solid #ecd9d9;
  background: #fff;
  box-shadow: -1px 7px 21px -1px rgba(133, 133, 133, 0.67); }
  .program-card .block-title-2 {
    font-size: 1.1875rem; }
  .program-card .block-title-1 {
    margin-bottom: 10px;
    margin-top: 5px;
    font-size: 1.125rem; }
  .program-card .content {
    color: #858585;
    font-weight: bold;
    line-height: 20px; }
  .program-card > .inline-list {
    margin-bottom: 10px; }

.program-status {
  padding: 4px 20px 4px 20px;
  background: #fab414;
  color: #fff;
  font-weight: bold;
  line-height: 1; }

.remaining-days {
  font-weight: bold; }
  .remaining-days span {
    font-weight: normal; }

.program-age {
  margin-bottom: 20px;
  text-align: right; }
  .program-age span {
    padding: 6px 12px;
    background: #0d1a34;
    color: #fff; }
  .program-age.expiring span {
    background: #d5d5d5;
    color: #1e3c78; }

.program-attributes {
  margin: 30px 0;
  grid-template-columns: repeat(auto-fill, minmax(31%, auto)); }
  .program-attributes .column {
    display: flex;
    align-items: center;
    justify-content: center; }

.invest-container {
  border-right: 1px solid #fab414; }
  .invest-container .invest-bg {
    margin-right: 5px;
    display: inline-block;
    width: 45px;
    height: 44px;
    background: url("../img/programa-stripe.png") no-repeat 0 0; }
  .invest-container .block-title {
    color: #0d1a34;
    font-size: 15px;
    font-weight: normal; }
  .invest-container p {
    color: #6e7073; }

.epidotisi-container {
  border-right: 1px solid #fab414; }
  .epidotisi-container .epidotisi-bg {
    margin-right: 5px;
    display: inline-block;
    width: 45px;
    height: 44px;
    background: url("../img/programa-stripe.png") no-repeat 0 -73px; }
  .epidotisi-container .block-title {
    color: #0d1a34;
    font-size: 15px;
    font-weight: normal; }
  .epidotisi-container p {
    color: #6e7073; }

.prothesmia-container .prothesmia-bg {
  margin-right: 5px;
  display: inline-block;
  width: 45px;
  height: 44px;
  background: url("../img/programa-stripe.png") no-repeat 0 -141px; }

.prothesmia-container .block-title {
  color: #0d1a34;
  font-size: 15px;
  font-weight: normal; }

.prothesmia-container p {
  color: #6e7073; }

.program-footer .button {
  padding: 9px 33px;
  font-weight: bold; }
  .program-footer .button:hover {
    text-decoration: underline; }

.xrimatodotiki-enisxisi {
  padding: 40px 0 60px 0;
  background: url("../img/3steps.jpg") no-repeat;
  background-size: cover;
  color: #fff; }
  .xrimatodotiki-enisxisi .grid-container {
    max-width: 58.75rem; }
    .xrimatodotiki-enisxisi .grid-container > .block-title-2 {
      padding-left: 20px;
      padding-bottom: 40px;
      position: relative; }
      .xrimatodotiki-enisxisi .grid-container > .block-title-2:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background: #fab414; }
  .xrimatodotiki-enisxisi .block-title-2 {
    color: #fff; }
  .xrimatodotiki-enisxisi p {
    margin-bottom: 40px; }
  .xrimatodotiki-enisxisi .inline-list {
    align-items: center; }
  .xrimatodotiki-enisxisi .button {
    padding: 9px 30px;
    font-weight: bold; }
  .xrimatodotiki-enisxisi .search-for-money {
    position: relative;
    color: #fff;
    font-style: italic; }
    .xrimatodotiki-enisxisi .search-for-money:hover {
      border: none;
      color: #fab414;
      text-decoration: underline; }
    .xrimatodotiki-enisxisi .search-for-money:after {
      content: '';
      margin-left: 5px;
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: middle;
      background: url("../img/white-arrow.png") no-repeat; }
  .xrimatodotiki-enisxisi .info-1 {
    padding-left: 80px;
    margin-top: 30px;
    padding-bottom: 50px;
    position: relative; }
    @media (max-width: 850px) {
      .xrimatodotiki-enisxisi .info-1 {
        padding-left: 65px; } }
    .xrimatodotiki-enisxisi .info-1:before {
      content: '';
      width: 62px;
      height: 80px;
      position: absolute;
      left: -18px;
      top: 0;
      background: url("../img/blue1.png") no-repeat;
      z-index: 2; }
    .xrimatodotiki-enisxisi .info-1:after {
      content: '';
      position: absolute;
      left: 0;
      top: 90px;
      bottom: -12px;
      width: 4px;
      background: #fab414; }
    .xrimatodotiki-enisxisi .info-1 .block-title-2 {
      padding-bottom: 15px; }
  .xrimatodotiki-enisxisi .info-2 {
    padding-left: 80px;
    padding-bottom: 50px;
    position: relative; }
    @media (max-width: 850px) {
      .xrimatodotiki-enisxisi .info-2 {
        padding-left: 65px; } }
    .xrimatodotiki-enisxisi .info-2:before {
      content: '';
      width: 69px;
      height: 80px;
      position: absolute;
      left: -18px;
      top: 0;
      background: url("../img/blue2.png") no-repeat;
      z-index: 2; }
    .xrimatodotiki-enisxisi .info-2:after {
      content: '';
      position: absolute;
      left: 0;
      top: 90px;
      bottom: -12px;
      width: 4px;
      background: #fab414; }
    .xrimatodotiki-enisxisi .info-2 .block-title-2 {
      padding-bottom: 15px; }
  .xrimatodotiki-enisxisi .info-3 {
    padding-left: 80px;
    position: relative; }
    @media (max-width: 850px) {
      .xrimatodotiki-enisxisi .info-3 {
        padding-left: 65px; } }
    .xrimatodotiki-enisxisi .info-3:before {
      content: '';
      width: 69px;
      height: 80px;
      position: absolute;
      left: -18px;
      top: 0;
      background: url("../img/blue3.png") no-repeat;
      z-index: 2; }
    .xrimatodotiki-enisxisi .info-3 .block-title-2 {
      padding-bottom: 15px; }

@media (max-width: 850px) {
  .xrimatodotiki-enisxisi .inline-list {
    display: block; }
    .xrimatodotiki-enisxisi .inline-list li {
      display: block; }
      .xrimatodotiki-enisxisi .inline-list li:not(:first-child) {
        margin-top: 20px;
        margin-left: 0; } }

.contact-us-block {
  padding: 30px 0;
  text-align: center; }
  @media (max-width: 850px) {
    .contact-us-block {
      padding: 15px 0; } }
  .contact-us-block .block-title-2 {
    color: #0d1a34; }
  .contact-us-block .inline-list {
    margin: 25px 0; }
    .contact-us-block .inline-list li {
      padding-left: 10px;
      border-left: 1px solid #fab414;
      color: #6e7073;
      line-height: 1;
      font-weight: bold; }
    @media (max-width: 850px) {
      .contact-us-block .inline-list {
        display: block; }
        .contact-us-block .inline-list li {
          margin: 0 0 15px 0;
          display: inline-block;
          text-align: left; } }
  .contact-us-block p {
    color: #6e7073; }
    @media (max-width: 850px) {
      .contact-us-block p {
        text-align: left; } }
  .contact-us-block .ways-to-communicate {
    grid-column-gap: 20px;
    margin-top: 55px;
    text-align: left; }
    .contact-us-block .ways-to-communicate .block-title-2 {
      margin: 15px 0; }
    .contact-us-block .ways-to-communicate p {
      margin-bottom: 15px; }
      .contact-us-block .ways-to-communicate p a {
        color: #1e3c78; }
        .contact-us-block .ways-to-communicate p a:hover {
          border: none;
          color: #fab414;
          text-decoration: underline; }

.search {
  margin-bottom: 15px; }
  .search .block-title-2 {
    margin-top: 40px;
    margin-bottom: 15px; }
  .search article {
    margin-bottom: 30px; }
    .search article .date, .search article .category {
      color: #6e7073;
      font-size: 15px;
      font-weight: bold; }
    .search article .article-title {
      margin-bottom: 4px;
      font-size: 15px; }
    .search article .article-summary {
      margin-bottom: 6px; }
    .search article .search-link {
      font-weight: bold; }

.ebooks h1 {
  margin-top: 40px;
  margin-bottom: 15px; }

.ebook {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ecd9d9; }
  .ebook:last-child {
    border: none; }
  .ebook .ebook-title-2 {
    margin-bottom: 25px;
    color: #0d1a34;
    font-size: 25px; }
  .ebook .date {
    color: #0d1a34;
    font-size: 11px;
    font-weight: bold; }
  .ebook .summary {
    margin-top: 10px;
    margin-bottom: 15px;
    color: #6e7073;
    font-weight: bold; }
  .ebook .button {
    padding: 11px 23px; }

nav {
  margin-top: 35px; }

.main-menu li:not(:first-child) {
  border-left: 1px solid #fab414;
  padding-left: 16px; }

.main-menu li a {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1; }
  .main-menu li a:hover {
    border: none;
    text-decoration: underline; }

.main-menu li.active a {
  font-weight: bold; }

*, *::before, *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 100%; }

body {
  margin: 0;
  padding: 0;
  background: #fff;
  color: #000;
  font-family: "PiraeusSans", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a.no-decoration:hover {
  text-decoration: none;
  border-bottom: 0; }

.arthrografia, .vivliothiki, .events, .article, .contact,
.exo-xrimatodotisi, .program-page, .ebooks {
  padding: 30px 0 40px 0;
  background: #f2f2f2; }
  .arthrografia .grid-container, .vivliothiki .grid-container, .events .grid-container, .article .grid-container, .contact .grid-container,
  .exo-xrimatodotisi .grid-container, .program-page .grid-container, .ebooks .grid-container {
    max-width: 70rem; }
  .arthrografia h1, .vivliothiki h1, .events h1, .article h1, .contact h1,
  .exo-xrimatodotisi h1, .program-page h1, .ebooks h1 {
    margin-top: 40px;
    margin-bottom: 15px; }

aside .banner {
  margin-bottom: 30px; }
  aside .banner img {
    max-width: initial; }
  aside .banner a:hover {
    border: none; }

aside .email {
  margin-bottom: 30px; }
  aside .email .block-title-2 {
    margin-bottom: 30px; }
  aside .email p {
    margin-bottom: 15px;
    color: #6e7073;
    font-weight: bold; }
  aside .email .button {
    padding: 11px 20px;
    font-weight: normal; }

header {
  position: relative;
  margin-bottom: 30px; }
  header:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 75px;
    background: #38404b;
    z-index: 1; }

.header-title {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0; }
  .header-title .grid {
    grid-column-gap: 0; }
  .header-title h1 {
    color: #fff;
    font-weight: bold;
    font-size: 3.125rem;
    line-height: 1.2em; }

.ie11 .header-title {
  position: absolute;
  top: 40%;
  left: 45%;
  transform: translateX(-45%); }

.ie11 .column.search-wrapper {
  width: 65%; }

.logo-section a:hover {
  border: none; }

.header-top {
  position: relative;
  z-index: 2;
  padding-top: 10px; }
  .header-top .search-wrapper {
    position: relative;
    grid-column: 3 / 3;
    text-align: right; }
  .header-top .blue-buttons-container {
    max-width: 61.5rem;
    margin-top: -80px; }
    .header-top .blue-buttons-container .bg-buttons {
      padding: 30px;
      font-size: 1.75rem;
      line-height: 1; }
      .header-top .blue-buttons-container .bg-buttons span {
        padding-left: 18px;
        padding-right: 0; }
        .header-top .blue-buttons-container .bg-buttons span:after {
          display: none; }

.front-header-image {
  margin-top: -60px;
  padding: 13% 0;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important; }

.mobile-menu-toggler {
  margin-left: 10px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 15px;
  height: 16px;
  box-shadow: inset 0 2px 0px 0 #858585; }
  .mobile-menu-toggler:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 15px;
    height: 2px;
    background: #858585; }
  .mobile-menu-toggler:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 15px;
    height: 2px;
    background: #858585; }

@media only screen and (max-width: 1220px) {
  .front-header-image {
    padding: 19% 0; } }

@media (min-width: 851px) and (max-width: 1100px) {
  .header-title {
    top: 44%; }
  .header-title .grid {
    grid-template-columns: repeat(auto-fill, minmax(51%, 1fr)); }
  .header-top .blue-buttons-container {
    margin-top: -70px;
    max-width: 56.25rem; }
    .header-top .blue-buttons-container .bg-buttons {
      padding: 23px;
      font-size: 1.3125rem; } }

.header-mobile {
  display: none; }

@media (max-width: 850px) {
  header {
    margin: 0; }
    header:before {
      height: 120px; }
  .header-top, .blue-buttons-container, nav,
  .header-top .front-header-image {
    display: none; }
  .header-mobile {
    display: block; }
    .header-mobile .front-header-image {
      padding: 30% 0;
      background-position: 100% !important; }
  .mobile-menu-with-logo {
    position: relative;
    display: block;
    z-index: 23;
    padding: 0 15px; }
    .mobile-menu-with-logo .logo-wrapper {
      width: 35%;
      z-index: 2;
      position: relative; }
    .mobile-menu-with-logo .search-wrapper {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      padding: 4px 25px;
      background: #fff;
      text-align: right;
      z-index: 1; }
  .header-image {
    position: relative; }
    .header-image h1 {
      padding: 0 25px;
      position: absolute;
      top: 30%;
      left: 0;
      transform: translateY(-30%);
      color: #fff;
      font-weight: bold;
      font-size: 2.125rem;
      line-height: 1.2em; }
  .mobile-menu {
    z-index: 3;
    display: none;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    background: #fff; }
    .mobile-menu.visible {
      opacity: 1;
      display: block; }
    .mobile-menu .menu-1, .mobile-menu .menu-2, .mobile-menu .menu-3 {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #fab414; }
      .mobile-menu .menu-1 h2, .mobile-menu .menu-2 h2, .mobile-menu .menu-3 h2 {
        padding: 0 25px;
        margin-bottom: 10px;
        font-size: 1.25rem;
        font-weight: 300; }
      .mobile-menu .menu-1 ul, .mobile-menu .menu-2 ul, .mobile-menu .menu-3 ul {
        padding: 0 25px; }
      .mobile-menu .menu-1 li, .mobile-menu .menu-2 li, .mobile-menu .menu-3 li {
        margin-bottom: 5px; }
        .mobile-menu .menu-1 li.active a, .mobile-menu .menu-2 li.active a, .mobile-menu .menu-3 li.active a {
          font-weight: bold; }
      .mobile-menu .menu-1 a, .mobile-menu .menu-2 a, .mobile-menu .menu-3 a {
        font-size: 1rem; } }

@media only screen and (max-width: 600px) {
  header:before {
    height: 100px; }
  .mobile-menu-with-logo .logo-wrapper {
    width: 60%; }
  .header-mobile .front-header-image {
    padding: 40% 0; }
  .header-mobile .header-image {
    margin-top: 35px; } }

footer p {
  font-size: 0.8125rem; }

footer a {
  color: #fff;
  text-decoration: none; }
  footer a:hover {
    border: none;
    text-decoration: underline; }

footer .widget-title {
  margin-bottom: 16px;
  color: #eee;
  font-size: 1.1875rem; }

.footer-top {
  padding: 30px 0 50px 0;
  background: #38404b;
  color: #fff; }
  @media (max-width: 768px) {
    .footer-top img {
      margin-bottom: 50px; } }
  .footer-top .epikoinonia li {
    margin-bottom: 20px;
    padding-left: 22px; }
    .footer-top .epikoinonia li a {
      font-weight: 300; }
    .footer-top .epikoinonia li.menu-item:first-child {
      background: url("../img/contact-icons.png") no-repeat 0 5px;
      font-size: 1.1875rem;
      font-weight: 500; }
      .footer-top .epikoinonia li.menu-item:first-child a {
        font-weight: 500; }
    .footer-top .epikoinonia li.menu-item:nth-child(2) {
      background: url("../img/contact-icons.png") no-repeat 0 -34px; }
    .footer-top .epikoinonia li.menu-item:last-child {
      background: url("../img/contact-icons.png") no-repeat 0 -72px; }
  .footer-top .simantikoi-sindesmoi {
    margin-bottom: 20px; }
    .footer-top .simantikoi-sindesmoi li {
      padding-left: 22px; }
      .footer-top .simantikoi-sindesmoi li.menu-item {
        background: url("../img/contact-icons.png") no-repeat 0 -177px; }
  .footer-top .bank-footer-menu {
    margin-top: 45px; }
    .footer-top .bank-footer-menu li {
      margin-bottom: 10px; }

.footer-bottom {
  padding: 20px 0; }
  .footer-bottom p {
    font-weight: bold;
    font-size: 0.8125rem; }
  .footer-bottom a {
    border: none;
    color: #858585;
    text-decoration: none;
    font-weight: bold; }
    .footer-bottom a:hover {
      border: none;
      color: #c72c2c; }
    .footer-bottom a.bank-link {
      color: #858585; }
      .footer-bottom a.bank-link:hover {
        color: #fab414;
        text-decoration: underline; }
      .footer-bottom a.bank-link + a {
        color: #1e3c78; }
        .footer-bottom a.bank-link + a:hover {
          color: #fab414;
          text-decoration: underline; }

.breadcrumb .grid-container {
  max-width: 73.125rem !important; }

.breadcrumb li {
  position: relative;
  font-size: 0.75rem;
  line-height: 1; }
  .breadcrumb li a {
    color: #1e3c78;
    line-height: 1; }
    .breadcrumb li a:hover {
      color: #fab414;
      border: none;
      text-decoration: underline; }
  .breadcrumb li.active {
    color: #858585; }
  .breadcrumb li:not(:first-child) {
    margin-left: 5px; }
  .breadcrumb li:not(:last-child) {
    padding-right: 5px;
    display: inline-block;
    vertical-align: middle;
    border-right: 1px solid #1e3c78; }

.article .grid-container {
  max-width: 73.125rem; }

.article .article-container {
  margin-top: 50px; }
  .article .article-container .grid {
    grid-column-gap: 25px; }

.article .article-wrapper {
  grid-column: 1 / 3; }
  @media (max-width: 850px) {
    .article .article-wrapper {
      grid-column: auto; } }
  .article .article-wrapper .block-title-2 {
    margin-bottom: 40px; }
  .article .article-wrapper .date {
    font-size: 0.6875rem;
    font-weight: bold; }
  .article .article-wrapper h1 {
    margin-bottom: 25px;
    margin-top: 0;
    font-size: 1.5625rem; }
  .article .article-wrapper img {
    margin-bottom: 10px; }

.article p {
  margin-bottom: 15px;
  color: #6e7073; }
  .article p a {
    color: #1e3c78;
    text-decoration: none; }
    .article p a:hover {
      color: #fab414;
      border-color: #fab414; }

.article .summary {
  color: #6e7073;
  font-weight: bold; }

.article .files {
  border-top: 0;
  padding-top: 0; }

.article aside {
  grid-column: 3 / 3; }
  @media (max-width: 850px) {
    .article aside {
      grid-column: auto; } }

.ie11 .article .article-wrapper {
  width: 68%; }

.file {
  padding: 10px;
  background: #fff;
  box-shadow: -1px 7px 21px -1px rgba(13, 26, 52, 0.67); }
  .file img {
    margin-bottom: 0; }

.search-program-page {
  padding: 30px 0 40px 0;
  background: #f2f2f2; }
  .search-program-page h1 {
    margin-top: 40px;
    margin-bottom: 15px; }
  .search-program-page .search-top {
    margin-top: 20px; }
  .search-program-page .perifereia-ependisis, .search-program-page .dikaiouxoi, .search-program-page .drastiriotita {
    margin-bottom: 20px;
    box-shadow: -1px 7px 21px -1px rgba(133, 133, 133, 0.67); }
  .search-program-page .search-main {
    margin-top: 20px; }
    .search-program-page .search-main .checkbox-container {
      margin-bottom: 10px;
      font-weight: normal; }
  .search-program-page .search-footer {
    margin-top: 20px;
    grid-template-columns: repeat(auto-fill, minmax(48%, auto)); }
    .search-program-page .search-footer p {
      text-align: center; }
  .search-program-page .results {
    grid-column: 2 / 4; }
    @media (max-width: 850px) {
      .search-program-page .results {
        margin-top: 30px;
        grid-column: auto; } }
  .search-program-page .company-size .six-columns {
    grid-template-columns: repeat(auto-fill, minmax(47%, auto)); }
  .search-program-page .company-size .radio-container {
    border: 1px solid #f2f2f2; }
    .search-program-page .company-size .radio-container .clearfix:last-child {
      margin-top: 10px; }
    .search-program-page .company-size .radio-container p {
      margin-top: 0;
      width: 80%; }
    .search-program-page .company-size .radio-container input {
      margin: 0;
      float: right; }

.ie11 .search-program-page .results {
  width: 68%; }

.input-container {
  margin-bottom: 20px;
  padding: 14px 18px;
  background: #fff;
  color: #0d1a34;
  box-shadow: -1px 7px 21px -1px rgba(13, 26, 52, 0.67); }
  .input-container input {
    box-shadow: none;
    width: 100%;
    border: 1px solid #d5d5d5; }

.select-container {
  padding: 14px 18px;
  background: #fff;
  color: #0d1a34; }
  .select-container h3 {
    margin-bottom: 5px;
    font-size: 1.1875rem; }
  .select-container select {
    margin-bottom: 5px;
    height: 46px;
    max-width: 400px;
    width: 100%;
    padding: 12px 24px;
    border: 1px solid #d5d5d5;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    font-size: 0.875rem; }

.compare {
  position: relative;
  display: inline-block;
  float: right;
  color: #6e7073;
  text-decoration: underline; }
  .compare:before {
    content: '';
    margin-right: 5px;
    width: 17px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    background: url("../img/form-icons.png") no-repeat 0 0; }

.clear-filters {
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  color: #6e7073;
  text-decoration: underline; }
  .clear-filters:before {
    content: '';
    margin-right: 5px;
    width: 17px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    background: url("../img/form-icons.png") no-repeat 0 -20px; }

.save-search {
  position: relative;
  display: inline-block;
  color: #6e7073;
  text-decoration: underline; }
  .save-search:before {
    content: '';
    margin-right: 5px;
    width: 17px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    background: url("../img/form-icons.png") no-repeat 0 -43px; }

.search-submit-wrapper {
  margin-top: 22px;
  text-align: center; }
  .search-submit-wrapper .button {
    padding: 16px 29px; }

.mobile-filter-toggler {
  display: none;
  background: #0d1a34;
  color: white;
  padding: 13px;
  text-align: center;
  border-radius: 2px; }
  @media (max-width: 850px) {
    .mobile-filter-toggler {
      display: block; } }

@media (max-width: 850px) {
  .filters-container {
    margin-top: 20px;
    display: none; }
    .filters-container.active {
      display: block; }
  .program-attributes {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto)); }
    .program-attributes .column {
      margin-bottom: 10px;
      align-items: flex-start;
      justify-content: flex-start; }
  .invest-container, .epidotisi-container {
    border: none; }
  .program-card .inline-list {
    display: inline-block; }
    .program-card .inline-list li {
      margin-left: 0;
      margin-bottom: 10px;
      display: inline-block; } }

.program-page .program-page-container {
  margin-top: 50px; }

.program-page .article-wrapper .no-bullets {
  margin-top: 30px; }
  .program-page .article-wrapper .no-bullets li {
    margin-bottom: 10px; }

.program-page p {
  color: #858585; }

.program-page .program-status span {
  display: inline-block; }

.program-page .invest-container, .program-page .epidotisi-container {
  border: none; }

.program-page .ui-accordion {
  box-shadow: none;
  border-bottom: 1px solid #d5d5d5; }
  .program-page .ui-accordion .ui-accordion-header {
    position: relative;
    padding: 15px 30px;
    background: none;
    box-shadow: none; }
    @media (max-width: 850px) {
      .program-page .ui-accordion .ui-accordion-header {
        padding-right: 30px;
        padding-left: 10px; } }
    .program-page .ui-accordion .ui-accordion-header h3 {
      padding-left: 10px;
      border-left: 4px solid #fab414;
      font-weight: bold;
      font-size: 1.5rem; }
      @media (max-width: 850px) {
        .program-page .ui-accordion .ui-accordion-header h3 {
          font-size: 0.9375rem; } }
    .program-page .ui-accordion .ui-accordion-header:after {
      position: absolute;
      top: 40%;
      right: 15px;
      transform: translate(-40%); }
      @media (max-width: 850px) {
        .program-page .ui-accordion .ui-accordion-header:after {
          right: 0;
          top: 30%;
          transform: translate(-30%); } }
    .program-page .ui-accordion .ui-accordion-header.ui-state-active {
      background: #fff; }
      .program-page .ui-accordion .ui-accordion-header.ui-state-active:after {
        transform: translate(-40%) rotate(180deg); }
  .program-page .ui-accordion .ui-accordion-content {
    padding: 14px 30px; }
    .program-page .ui-accordion .ui-accordion-content p {
      color: #0d1a34; }
      .program-page .ui-accordion .ui-accordion-content p a {
        color: #0d1a34; }
        .program-page .ui-accordion .ui-accordion-content p a:hover {
          border: none;
          color: #fab414;
          text-decoration: underline; }

.program-page .program-attributes .column {
  padding: 25px;
  background: #fff;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: -1px 7px 21px -1px rgba(133, 133, 133, 0.67); }

.program-page .program-attributes .block-title, .program-page .program-attributes p {
  margin-left: 10px;
  font-weight: 300;
  font-size: 1.1875rem; }

.files {
  padding-top: 15px;
  border-top: 1px solid #d5d5d5; }
  .files img {
    margin-right: 10px; }
  .files a {
    border: none; }
    .files a:hover {
      border: none; }

.ie11 .program-attributes .column:not(:last-child) {
  margin-right: 20px; }

.ie11 .files .three-columns .column:not(:last-child) {
  margin-right: 20px; }

.error-title {
  font-size: 8.75rem;
  font-weight: bold; }

.error-subtitle {
  margin-bottom: 10px;
  font-size: 2.1875rem;
  font-weight: bold; }

.error404 {
  padding: 12px 0; }
  .error404 .button {
    margin-top: 10px;
    padding: 9px 24px;
    font-weight: bold; }
    .error404 .button:hover {
      text-decoration: underline; }

@media print {
  header, footer, aside, .social-media-share,
  .breadcrumb, .see-more, #return-to-top, .files {
    display: none; } }
