.newsletter-block {
  margin-top: 30px;
  @include bg-image(20px);

  .three-columns {
    grid-template-columns: repeat(auto-fill, minmax(33%, auto));
    @include tablet-portrait-down {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto));
    }
  }

  .block-title-2 {
    margin-bottom: 30px;
  }

  .button-wrapper {
    text-align: right;
    @include tablet-portrait-down {
      margin-top: 20px;
      text-align: left;
    }
  }
  .button {
    padding: 15px 19px;
  }
}


.ie11 {
  .newsletter-block {
    .column {
      width: 48%;
    }
  }
}
