.bg-buttons {
  @include bg-button($blue, $yellow);
  &.active {
    background: lighten(desaturate(adjust-hue($blue, -2), 23.64), 13.73);
  }
}

.btn-read-more {
  padding: 12px 31px;
  font-weight: 600;
}
