.events-section {
  padding: 30px 0;
  @include tablet-portrait-down {
    padding: 15px 0;
  }
  background: url('../img/events-bg.png') repeat;
  .grid {
    grid-column-gap: 60px;
    @include tablet-portrait-down {
      grid-column: auto;
    }
  }

  .block-title-2 {
    margin-bottom: 30px;
  }
}

.ie11 {
  .events-section {
    .column.events-read-more {
      width: 23%;
    }

    .column.events-section-wrapper {
      width: 73%;
      .column {
        width: 73%;
        &:first-child {
          width: 23%;
        }
      }
    }
  }
}

.events-section-wrapper {
  grid-column: 1 / 3;
  @include tablet-portrait-down {
    grid-column: auto;
  }

  .two-columns {
    grid-template-columns: repeat(auto-fill, minmax(21%, auto));
    @include tablet-portrait-down {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto));
    }
  }

  .grid {
    .column {
      grid-column: 1 / 1;
      &:last-child {
        grid-column: 2 / 7;
      }
      @include tablet-portrait-down {
        grid-column: auto;
        margin-bottom: 20px;
        &:last-child {
          grid-column: auto;
        }
        &:first-child {
          display: none;
        }
      }
    }
  }

  .date {
    color: $dark-gray;
    font-weight: bold;
  }
}

.all-events-block {
  background: $white;
  position: relative;
  padding: 30px 10px 0 30px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 15px;
    width: 4px;
    background: $yellow;
  }

  p {
    color: $light-black;
    font-size: rem-calc(19);
    font-style: italic;
    &:not(.link) {
      margin-bottom: 55px;
    }
  }
}


.event-block-title {
  font-size: rem-calc(32);
  line-height: 35px;
  a {
    color: $black;
    text-decoration: none;
    &:hover {
      border: none;
    }
  }
}

.event-block-content {
  color: $dark-gray;
  font-weight: bold;
}

.events-read-more {
  grid-column: 3 / 4;
  @include tablet-portrait-down {
    grid-column: auto;
  }

  ul {
    margin-top: 30px;
    padding: 0 30px;
  }

  li {
    position: relative;
    padding-left: 10px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 10px;
      top: -10px;
      width: 1px;
      background: $lighten-red;
    }
    &:not(:last-child) {
      &:before {
        content: '';
        position: absolute;
        left: -30px;
        right: 0;
        height: 1px;
        background: $lighten-red;
        bottom: 0;
      }
    }

    .date {
      color: $black;
      font-weight: bold;
    }

    a {
      color: $blue;
      font-weight: bold;
      &:hover {
        border: none;
        color: $yellow;
        text-decoration: underline;
      }
    }
  }
}

.view-all-events {
  margin-top: 20px;
  display: none;
  position: relative;
  a {
    color: $blue;
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
    &:after {
      content: '';
      margin-left: 10px;
      width: 23px;
      height: 23px;
      display: inline-block;
      vertical-align: middle;
      background: url('../img/more-events-arrow.png') no-repeat;
    }
  }
}

@include tablet-portrait-down {
  .view-all-events {
    display: block;
  }
}
