.card {
  position: relative;
  box-shadow: -1px 11px 23px 1px rgba($dark-gray,0.53);
  background: $white;
  @include tablet-portrait-down {
    margin-bottom: 20px;
  }
  img {
    display: block;
    margin: 0 auto;
  }
}

.grid{
  .card{
    height:100%;
  }
}

.link {
  text-align: right;
  position: absolute;
  bottom: 10px;
  right: 10px;
  a {
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url('../img/round-arrow.png') no-repeat;
    &:hover {
      border: none;
    }
  }
}

.card-category {
  color: $brown;
  font-size: rem-calc(11);
  text-transform: uppercase;
}

.date-wrapper {
  text-align: right;
  .date {
    color: $gray;
    font-size: rem-calc(11);
    font-weight: bold;
  }
}

.card-title {
  margin-top: 10px;
  font-size: rem-calc(19);
  line-height: 20px;
  a {
    color: $blue;
    &:hover {
      border: none;
      color: $yellow;
      text-decoration: underline;
    }
  }
}

.card-content {
  background: $white;
  padding: 10px;

  .six-columns {
    grid-template-columns: repeat(auto-fill, minmax(31%, auto));
  }

  .content {
    margin-bottom: 40px;
    @include tablet-portrait-down {
      margin-bottom: 10px;
    }
  }

  .body {
    margin-top: 10px;
    position: relative;
    padding-left: 20px;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 35px;
      background: $blue;
    }
  }
}

.bg-card {
  padding: 20px;
  background: $light-gray;

  .card-content {
    padding: 0;
    background: $light-gray;
  }
  p {
    color: $dark-gray;
  }

  .body {
    margin-bottom: 0;
  }

  .link {
    a {
      background: none;
      width: auto;
      height: auto;
      border: none;
      color: $dark-gray;
      font-style: italic;
      text-decoration: underline;
      &:hover {
        border: none;
      }
    }
  }
}


.file-card {
  .three-columns {
    grid-template-columns: repeat(auto-fill, minmax(17%, auto));
  }

  .column {
    &:last-child {
      grid-column: 2 / 5;
    }
  }

  .card-category {
    color: lighten(desaturate(adjust-hue($blue, -2), 23.64), 13.73);;
    text-transform: uppercase;
  }

  .card-title {
    font-size: rem-calc(15);
  }

  .pdf {
    width: 47px;
	  height: 56px;
    background: url('../img/file.png') no-repeat;
    display: inline-block;
  }

  .file-size {
    font-size: 11px;
    color: $darker-gray;
  }

  .file {
    width: 47px;
	  height: 55px;
    background: url('../img/file.png') no-repeat -99px 0;
    display: inline-block;
  }

  .file-download {
    text-align: right;
    a {
      display: inline-block;
      width: 22px;
      height: 22px;
      background: url('../img/download-file.png') no-repeat;
      &:hover {
        border: none;
      }
    }
  }

  .date {
    color: $dark-gray;
    font-size: rem-calc(11);
    font-weight: bold;
  }
}

.event-card {
  .date-wrapper {
    text-align: left;
  }

  .card-content {
    .body {
      padding: 0;
      &:before {
        display: none;
      }
    }
  }
}


.ie11 {

  .card {
    margin-bottom: 20px;
  }

  .file-card {
    .three-columns {
      > .column {
        &:last-child {
          width: 68%;
        }
      }
    }
  }

  .event-card {
    .column {
      width: 100%;
    }
  }
}
