$grid-row-gap: 15px;
$grid-column-gap: 15px;

.grid-container {
  max-width: rem-calc($grid-container);
  margin: 0 auto;
  padding: 0 15px;
}

.grid {
  display: grid;
  grid-row-gap: $grid-row-gap;
  grid-column-gap: $grid-column-gap;
  grid-auto-flow: row;
  @include mobile-only {
    grid-row-gap: 0;
  }
}

.subgrid {
  display: subgrid;
  @include mobile-only {
    grid-row-gap: 0;
  }
}

.one-column {
  grid-template-columns: repeat(auto-fill, minmax(8.33333%, auto));
  @include tablet-portrait {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
  }

  @include mobile-only {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
  }
}

.two-columns {
  grid-template-columns: repeat(auto-fill, minmax(15%, auto));
  @include tablet-portrait {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
  }

  @include mobile-only {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
  }
}

.three-columns {
  grid-template-columns: repeat(auto-fill, minmax(32%, auto));
  @include tablet-portrait {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
  }

  @include mobile-only {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
  }
}

.four-columns {
  grid-template-columns: repeat(auto-fill, minmax(25%, auto));
  @include tablet-portrait {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
  }

  @include mobile-only {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
  }
}

.six-columns {
  grid-template-columns: repeat(auto-fill, minmax(49%, auto));
  @include tablet-portrait {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
  }

  @include mobile-only {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
  }
}

.seven-columns {
  grid-template-columns: repeat(auto-fill, minmax(58.33333%, auto));
  @include tablet-portrait {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
  }

  @include mobile-only {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
  }
}

.align-baseline {
  align-items: baseline;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-right {
  justify-content: flex-end;
}


.ie11 {
  .grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .one-column {
    .column {
      width: 8.3333%;
      padding: 0 15px;
      @include tablet-portrait {
        width: 100%;
      }
      @include mobile-only {
        width: 100%;
      }
    }
  }

  .two-columns {
    .column {
      width: 15%;
      padding: 0 15px;
      @include tablet-portrait {
        width: 100%;
      }
      @include mobile-only {
        width: 100%;
      }
    }
  }

  .three-columns {
    .column {
      width: 32%;
      padding: 0 15px;
      @include tablet-portrait {
        width: 100%;
      }
      @include mobile-only {
        width: 100%;
      }
    }
  }

  .four-columns {
    .column {
      width: 25%;
      padding: 0 15px;
      @include tablet-portrait {
        width: 100%;
      }
      @include mobile-only {
        width: 100%;
      }
    }
  }

  .six-columns {
    .column {
      width: 49%;
      padding: 0 15px;
      @include tablet-portrait {
        width: 100%;
      }
      @include mobile-only {
        width: 100%;
      }
    }
  }

  .seven-columns {
    .column {
      width: 58.33333%;
      padding: 0 15px;
      @include tablet-portrait {
        width: 100%;
      }
      @include mobile-only {
        width: 100%;
      }
    }
  }
}
