.search-program {
  margin-top: -30px;
  padding-top: 50px;
  padding-bottom: 30px;
  background: $light-gray;
  .form-container {
    .inline-list {
      li {
        background: $white;
      }
    }
  }
}

.form-container {
  margin-top: 30px;

  .inline-list {
    li {
      max-width: 182px;
      width: 100%;
      box-shadow: -1px 7px 21px -1px rgba($light-black, 0.67);
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  .radio-buttons-container {
    margin-bottom: 40px;
    &.activity-container {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto));
    }
    .dropdown {
      .dropdown-toggle {
        padding: 0;
        min-width: auto;
        box-shadow: none;
        &:after {
          bottom: -22px;
          top: auto;
          @include tablet-portrait-down {
            bottom: 0;
          }
        }
        p {
          min-height: 108px;
          padding: 10px;
          margin-bottom: 60px;
          font-weight: bold;
          @include tablet-portrait-down {
            margin-bottom: 44px;
          }
        }
      }

      .dropdown-content {
        left: auto;
        width: 750px;
        .six-columns {
          grid-template-columns: repeat(auto-fill, minmax(48%, auto));
        }
      }
      .radio-container {
        background: $white;
        padding-left: 25px;
        p {
          min-height: 64px;
          padding: 10px;
          margin-bottom: 60px;
        }

        input {
          position: absolute;
          margin: 0;
        }
      }
    }
  }

  .form-actions {
    margin-top: 50px;
    text-align: center;
    button {
      padding: 16px 38px;
    }
  }

}

.radio-container {
  background: $white;
  padding-left: 0;
  p {
    padding: 10px;
    margin-bottom: 60px;
  }

  input {
    position: static;
    display: block;
    margin: 10px;
  }
}

.radio-buttons-container {
  .radio-container {
    p {
      min-height: 108px;
    }
  }
}

.type-of-company {
  .six-columns {
    grid-template-columns: repeat(auto-fill, minmax(48%, auto));
  }
}


@include tablet-portrait {
  .form-container {
    .activity-container {
      .inline-list {
        display: block;
        li {
          margin-bottom: 20px;
          width: 33.333%;
          display: inline-block;
          float: left;
          &:not(:first-child) {
            margin-left: 0;
            margin-right: 15px;
          }
          &:first-child {
            margin-left: 0;
            margin-right: 15px;
          }
          .radio-container {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@include tablet-portrait-down {
  .form-container {
    .radio-buttons-container {
      .dropdown {
        .dropdown-content {
          max-width: 690px;
          width: 100%;
        }
      }
    }
  }

  .type-of-company {
    margin-top: 40px;
  }
}

@include mobile-only {
  .form-container {
    .activity-container {
      .inline-list {
        display: block;
        li {
          margin-bottom: 20px;
          float: left;
          width: 45%;
          display: inline-block;
          &:not(:first-child) {
            margin-left: 0;
            margin-right: 14px;
          }
          &:first-child {
            margin-left: 0;
            margin-right: 15px;
          }
          .radio-container {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
