.arthrografia-vivliothiki {
  padding: 30px 0;
  @include tablet-portrait-down {
    padding: 15px 0;
  }

  .grid {
    grid-column-gap: 60px;

    .card {
      height: auto;
    }

  }
}

.ie11 {
  .arthrografia-vivliothiki {
    .column {
      width: 72%;
      &.vivliothiki-wrapper {
        width: 25%;
        margin-left: 15px;
      }
    }
  }
}

.arthrografia-wrapper {
  grid-column: 1 / 3;
  @include tablet-portrait-down {
    grid-column: auto;
  }
}

.vivliothiki-wrapper {
  grid-column: 3 / 4;
  @include tablet-portrait-down {
    margin-bottom: 20px;
    grid-column: auto;
  }

  ul {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      width: 1px;
      background: $border-color;
    }
  }

  li {
    position: relative;
    padding-left: 10px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    &:before {
      content: '';
      position: absolute;
      width: 108px;
      height: 1px;
      background: $border-color;
      left: -30px;
      bottom: 0;
    }
  }

  a {
    color: $black;
    font-weight: bold;
  }

  .grid {
    .card {
      height: auto;
    }
  }
}

.read-more-links {
  margin-top: 30px;
  font-size: rem-calc(12);
  font-style: italic;
  li {
    &:not(:first-child) {
      padding-right: 10px;
      margin-right: 5px;
      margin-left: 5px;
      border-right: 1px solid $yellow;
    }
    &:last-child {
      border-right: none;
    }
  }
  a {
    color: $blue;
    line-height: 1;
  }
}

@include tablet-portrait-down {
  .read-more-links {
    margin-bottom: 20px;
    display: block;
    li {
      margin-top: 10px;
      display: inline-block;
      &:first-child {
        display: block;
      }
    }
  }
}
