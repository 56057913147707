.filter-container {
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid $border-color;
}

.filter-title {
  margin-bottom: 5px;
  color: $black;
  font-size: rem-calc(15);
  font-weight: bold;
}


@include tablet-portrait-down {
  .filter-container {
    .inline-list {
      display: block;
      li {
        margin: 0 0 15px 0;
        display: inline-block;
        text-align: left;
      }
    }
  }
}

.ie11 {
  .filters {
    .four-columns {
      .column {
        width: 36%;
        &:last-child {
          width: 50%;
        }
      }
    }
  }
}
