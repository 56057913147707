nav {
  margin-top: 35px;
}

.main-menu {
  li {
    &:not(:first-child) {
      border-left: 1px solid $yellow;
      padding-left: 16px;
    }
    a {
      font-size: rem-calc(18);
      font-weight: 300;
      line-height: 1;
      &:hover {
        border: none;
        text-decoration: underline;
      }
    }
    &.active {
      a {
        font-weight: bold;
      }
    }
  }
}
