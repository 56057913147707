.contact {

  [type="text"], [type="email"],
  select, textarea {
    max-width: 500px;
    width: 100%;
  }

  .grid-container {
    &.contact-container {
      margin-top: 50px;
      max-width: rem-calc($grid-container);
    }

    .grid {
      grid-column-gap: 25px;
    }
  }

  .contact-wrapper {
    grid-column: 1 / 3;
    @include tablet-portrait-down {
      grid-column: auto;
    }

    .block-title-2 {
      margin-bottom: 40px;
    }
  }

  aside {
    grid-column: 3 / 3;
    @include tablet-portrait-down {
      margin-top: 40px;
      grid-column: auto
    }
  }
}

.klados-epixeirisis {
  .opened {
    margin-top: 0;
  }
}

.form-container {
  .klados-epixeirisis {
    .radio-buttons-container {
      .radio-container {
        p {
          margin-bottom: 35px;
        }
      }
      .dropdown-toggle {
        p {
          margin-bottom: 83px;
          @include tablet-portrait-down {
            margin-bottom: 60px;
          }
        }
        &:after {
          bottom: 0;
        }
      }
    }
  }
}

.form-container {

  h6 {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: rem-calc(19);
  }


  .poso-ependisis {
    margin-bottom: 35px;
  }

  .perifereia {
    margin-bottom: 35px;
  }

  .edra-epixeirisis {
    margin-bottom: 35px;
  }

  .info-about-program {
    margin-bottom: 35px;
    .checkbox-container {
      font-weight: normal;
    }
  }

  .company-name, .other-question,
  .representative-name, .representative-surname,
  .representative-email, .telephone {
    margin-bottom: 35px;
  }

  .gdpr {
    margin-bottom: 35px;
    p {
      margin-bottom: 10px;
      color: $black;
      a {
        color: $blue;
        &:hover {
          border: none;
          color: $yellow;
          text-decoration: underline;
        }
      }
    }
    label {
      font-weight: normal
    }
  }

  .form-submit {
    .button {
      padding: 12px 47px;
      font-size: rem-calc(14);
    }
  }
}

.search-for {
  margin-bottom: 35px;
  .inline-list {
    li {
      width: auto;
      max-width: initial;
      box-shadow: none;
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
  .radio-container {
    background: transparent;
    input {
      margin: 0;
      display: inline-block;
    }
    p {
      margin: 0;
      padding: 0;
      display: inline-block;
      font-weight: 300;
      font-size: 20px;
    }
  }
}

.company-size {
  margin-bottom: 35px;
  .four-columns {
    grid-template-columns: repeat(auto-fill, minmax(22%, auto));
    @include tablet-portrait-down {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto));
    }
  }

  .grid {
    grid-column-gap: 15px;
  }

  .radio-container {
    padding: 10px;
    width: 100%;

    input {
      margin: 0 0 10px auto;
    }

    .comp-size {
      float: left;
      width: 24px;
      height: 25px;
    }

    .info {
      float: right;
      width: 18px;
      height: 18px;
      background: url('../img/info-icon.png') no-repeat;
    }

    p {
      padding: 0;
      margin: 10px 0 0 0;
      display: inline-block;
      color: $black;
      font-weight: 300;
    }
  }

  .radio {
    &:nth-child(1) {
      .radio-container {
        .comp-size {
          background: url('../img/company-size.png') no-repeat 0 -5px;
        }
      }
    }

    &:nth-child(2) {
      .radio-container {
        .comp-size {
          width: 50px;
          height: 25px;
          background: url('../img/company-size.png') no-repeat 0 -137px;
        }
      }
    }

    &:nth-child(3) {
      .radio-container {

        .comp-size {
          width: 77px;
          height: 25px;
          background: url('../img/company-size.png') no-repeat 0 -277px;
        }
      }
    }

    &:nth-child(4) {
      .radio-container {
        .comp-size {
          width: 100px;
          height: 25px;
          background: url('../img/company-size.png') no-repeat 0 -444px;
        }
      }
    }
  }
}

@include tablet-portrait-down {
  .search-for {
    .inline-list {
      display: block;
      li {
        display: block;
        &:not(:first-child) {
          margin-top: 5px;
          margin-left: 0;
        }
      }
    }
  }

  .radio-container {
    margin-bottom: 20px;
    p {
      margin-bottom: 20px;
    }
  }

  .form-container {
    .radio-buttons-container {
      grid-template-columns: repeat(auto-fill, minmax(45%, auto))
    }
  }
}

.ie11 {
  .three-columns {
    .contact-wrapper {
      width: 68%;
    }
  }
}
