.error-title {
  font-size: rem-calc(140);
  font-weight: bold;
}

.error-subtitle {
  margin-bottom: 10px;
  font-size: rem-calc(35);
  font-weight: bold;
}

.error-desc {}

.error404 {
  padding: 12px 0;
  .button {
    margin-top: 10px;
    padding: 9px 24px;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
}
